import i18n from "@/i18n";
import { TableConfig } from "@/models/table";

const possibleStatus = ["inactive", "active", "expired"];
export type AtmoDeviceStatuses = typeof possibleStatus[number];

export interface AtmoDevicePod {
  publicDeviceId: string;
  podId: string;
  roomName: string;
  licenseKey: string;
}

export interface AtmoDevice {
  id: string;
  name: string;
  status: AtmoDeviceStatuses;
  pod: AtmoDevicePod;
}

export interface AtmoParameter {
  parameterName: string;
  parameterValue: string;
}

export const associateDialogRoomConfig = {
  roomName: {
    dtoName: "roomName",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  overallState: {
    dtoName: "overallState",
    sortable: false,
    filterable: false,
    label: i18n.t("common.overallState"),
  },
} as TableConfig;

export const atmoDeviceConfig = {
  id: {
    dtoName: "id",
    sortable: false,
    filterable: true,
    label: "ATMO ID",
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: "Name",
  },
  status: {
    dtoName: "status",
    sortable: true,
    filterable: false,
    label: "Status",
  },
  pod: {
    dtoName: "pod",
    sortable: false,
    filterable: false,
    label: "Associated room",
  },
} as TableConfig;

export interface MeasurementListElement {
  id: string;
  title: string;
  icon: string;
  subtitle: string;
  prefix: string;
  suffix: string;
  scores?: {
    invert: boolean;
    step1: number;
    step2: number;
    step3: number;
  };
}

export interface AtmoDevicesResponseItemLatestMeasurement {
  dtm: number;
  iaqi: number;
  eiaqi: number;
  avti: number;
  tci: number;
  co2: number;
  pm1: number;
  pm25: number;
  pm10: number;
  temperature: number;
  voc: number;
  humidity: number;
  ch2o: number;
  vocindex: number;
  noxindex: number;
  noise: number;
  light: number;
  pm4: number;
  abs_humidity: number;
  pressure: number;
}

export interface AtmoDevicesResponseItemMeasurement {
  device_id: string;
  dtm: number;
  iaqi: {
    value: number;
    level: string;
  };
  eiaqi: {
    value: number;
    level: string;
  };
  avti: {
    value: number;
    level: string;
  };
  tci: {
    value: number;
  };
  aqi: {
    value: number;
  };
  co: number;
  co2: number;
  no2: number;
  o3: number;
  pm1: number;
  pm25: number;
  pm10: number;
  pm05_num: number;
  pm1_num: number;
  pm4_num: number;
  pm10_num: number;
  pm25_num: number;
  temperature: number;
  voc: number;
  humidity: number;
  ch2o: number;
  vocindex: number;
  noxindex: number;
  noise: number;
  light: number;
  color_temperature: number;
  people: number;
  pm4: number;
  abs_humidity: number;
  pressure: number;
  interval: number;
}

export interface AtmoDevicesResponseItem {
  id: string;
  name: string;
  status: string;
  latestMeasurement: AtmoDevicesResponseItemLatestMeasurement;
  measurements: AtmoDevicesResponseItemMeasurement[];
}

export interface AtmoDevicesResponse {
  devices: AtmoDevicesResponseItem[];
}

export const measurementsList = [
  {
    id: "iaqi",
    title: "IAQI",
    icon: "vi-atmo-iaqi",
    subtitle: "",
    prefix: "",
    suffix: "",
    scores: { invert: false, step1: 40, step2: 60, step3: 80 },
  },
  {
    id: "co2",
    title: "CO2",
    icon: "vi-atmo-co2",
    subtitle: "",
    prefix: "",
    suffix: "ppm",
    scores: { invert: true, step1: 600, step2: 1000, step3: 1500 },
  },
  {
    id: "temperature",
    title: "Temperature",
    icon: "vi-atmo-temperature",
    subtitle: "",
    prefix: "",
    suffix: "° Celsius",
    scores: { invert: false, step1: 15, step2: 17, step3: 20 },
  },
  {
    id: "noise",
    title: "Noise",
    icon: "vi-atmo-noise",
    subtitle: "",
    prefix: "",
    suffix: "dB",
    scores: { invert: true, step1: 40, step2: 60, step3: 80 },
  },
  {
    id: "pm1",
    title: "PM1",
    icon: "vi-atmo-pm1",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 15, step2: 35, step3: 62 },
  },
  {
    id: "pm25",
    title: "PM2.5",
    icon: "vi-atmo-pm25",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 20, step2: 50, step3: 90 },
  },
  {
    id: "pm4",
    title: "PM4",
    icon: "vi-atmo-pm4",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 20, step2: 50, step3: 90 },
  },
  {
    id: "pm10",
    title: "PM10",
    icon: "vi-atmo-pm10",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 30, step2: 75, step3: 125 },
  },
  {
    id: "light",
    title: "Light",
    icon: "vi-atmo-light",
    subtitle: "",
    prefix: "",
    suffix: "lux",
  },
  {
    id: "humidity",
    title: "Humidity",
    icon: "vi-atmo-humidity",
    subtitle: "",
    prefix: "",
    suffix: "%",
  },
  {
    id: "abs_humidity",
    title: "Abs humidity",
    icon: "vi-atmo-abs-humidity",
    subtitle: "",
    prefix: "",
    suffix: "g/m3",
  },
  {
    id: "pressure",
    title: "Pressure",
    icon: "vi-atmo-pressure",
    subtitle: "",
    prefix: "",
    suffix: "mbar",
  },
  {
    id: "voc",
    title: "TVOC",
    icon: "vi-atmo-voc",
    subtitle: "",
    prefix: "",
    suffix: "ppm",
    scores: { invert: true, step1: 0.088, step2: 0.262, step3: 0.661 },
  },
  {
    id: "noxindex",
    title: "NOX index",
    icon: "vi-atmo-noxindex",
    subtitle: "",
    prefix: "",
    suffix: "",
  },
  {
    id: "vocindex",
    title: "VOC index",
    icon: "vi-atmo-vocindex",
    subtitle: "",
    prefix: "",
    suffix: "",
    scores: { invert: true, step1: 200, step2: 250, step3: 350 },
  },
  {
    id: "ch2o",
    title: "CH2O",
    icon: "vi-atmo-ch2o",
    subtitle: "",
    prefix: "",
    suffix: "ppm",
    scores: { invert: true, step1: 0.06, step2: 0.11, step3: 0.31 },
  },
];

export const measurementsListPublic = [
  {
    id: "iaqi",
    title: "IAQI",
    icon: "vi-atmo-iaqi",
    subtitle: "",
    prefix: "",
    suffix: "%",
    scores: { invert: false, step1: 40, step2: 60, step3: 80 },
  },
  {
    id: "co2",
    title: "CO2",
    icon: "vi-atmo-co2",
    subtitle: "",
    prefix: "",
    suffix: "ppm",
    scores: { invert: true, step1: 600, step2: 1000, step3: 1500 },
  },
  {
    id: "temperature",
    title: "Temperature",
    icon: "vi-atmo-temperature",
    subtitle: "",
    prefix: "",
    suffix: "° Celsius",
    scores: { invert: false, step1: 15, step2: 17, step3: 20 },
  },
  {
    id: "noise",
    title: "Noise",
    icon: "vi-atmo-noise",
    subtitle: "",
    prefix: "",
    suffix: "dB",
    scores: { invert: true, step1: 40, step2: 60, step3: 80 },
  },
  {
    id: "light",
    title: "Light",
    icon: "vi-atmo-light",
    subtitle: "",
    prefix: "",
    suffix: "lux",
  },
  {
    id: "humidity",
    title: "Humidity",
    icon: "vi-atmo-humidity",
    subtitle: "",
    prefix: "",
    suffix: "%",
  },
  {
    id: "pressure",
    title: "Pressure",
    icon: "",
    subtitle: "",
    prefix: "",
    suffix: "mbar",
  },
  {
    id: "voc",
    title: "TVOC",
    icon: "vi-atmo-voc",
    subtitle: "",
    prefix: "",
    suffix: "ppm",
    scores: { invert: true, step1: 0.088, step2: 0.262, step3: 0.661 },
  },
  {
    id: "pm1",
    title: "PM1",
    icon: "",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 15, step2: 35, step3: 62 },
  },
  {
    id: "pm25",
    title: "PM2.5",
    icon: "vi-atmo-pm25",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 20, step2: 50, step3: 90 },
  },
  {
    id: "pm10",
    title: "PM10",
    icon: "",
    subtitle: "",
    prefix: "",
    suffix: "μg/m3",
    scores: { invert: true, step1: 30, step2: 75, step3: 125 },
  },
  {
    id: "ch2o",
    title: "CH2O",
    icon: "",
    subtitle: "",
    prefix: "",
    suffix: "",
    scores: { invert: true, step1: 0.06, step2: 0.11, step3: 0.31 },
  },
];
