var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [_c("va-icon", { attrs: { icon: "vi vi-atmo-iaqi", size: "l" } })],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "p",
              { staticClass: "text-h3 font-weight-bold d-inline-block mb-0" },
              [_vm._v("IAQI index")]
            )
          ]),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c("div", { staticClass: "align-end text-right" }, [
              _c(
                "p",
                { staticClass: "text-h5 grey--text font-weight-bold mb-0" },
                [_vm._v("%")]
              )
            ])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-8 mb-8" }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-justify" }, [
      _c("p", [
        _vm._v(
          " IAQI ranges on a scale from 0 (severely polluted air) to 100 (very clean). "
        )
      ]),
      _c("p", [_vm._v("Atmo’s IAQI is calculated based on:")]),
      _c("p", [_vm._v("• CO2, PM1, PM2.5 and PM10")]),
      _c("p", [
        _vm._v(
          " • VOCs (volatile organic compounds) such as formaldehyde, tetrachloroethylene, acetone, propylene, etc. "
        )
      ]),
      _c("div", { staticClass: "text-center" }, [
        _c("img", {
          staticClass: "mx-auto",
          staticStyle: { height: "auto", "max-width": "100%", width: "750px" },
          attrs: {
            src: require("@/assets/images/integrations/atmo-aqs.png"),
            alt: "ATMO"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }