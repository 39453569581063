import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Tag, TagDeserializer } from "@/models/tag";
import TagService from "@/services/tagService";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import * as listable from "./listableModule";
import cloneDeep from "lodash/clone";

export interface TagState extends listable.ListableState {
  currentTag: Tag;
  tags: Tag[];
  editing: boolean;
}

type TagContext = ActionContext<TagState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    tags: [],
    currentTag: {
      id: "",
      name: "",
    },
    editing: false,
  },
};

export const state = (): TagState => cloneDeep(initialState);

export const getters: GetterTree<TagState, RootState> = {
  ...listable.getters,
  ...{
    currentTag: (state): Tag | undefined => {
      return state.currentTag;
    },
    tags: (state): Tag[] => {
      return state.tags;
    },
    editing: (state): boolean => {
      return state.editing;
    },
  },
};
export const mutations: MutationTree<TagState> = {
  ...listable.mutations,
  ...{
    setCurrentTag(state: TagState, tag: Tag) {
      state.currentTag = tag;
    },
    resetCurrentTag(state: TagState) {
      state.currentTag = {
        id: "",
        name: "",
      };
    },
    setTags(state: TagState, tags: Tag[]) {
      state.tags = tags;
    },
    updateTag(state: TagState, currentTag: Tag) {
      state.tags = state.tags.map((tag) => {
        if (tag.id === currentTag.id) {
          return currentTag;
        }
        return tag;
      });
    },
    deleteTag(state: TagState, currentTag: Tag) {
      state.tags = state.tags.filter((tag) => {
        return tag.id !== currentTag.id;
      });
    },
    addTag(state: TagState, currentTag: Tag) {
      state.tags.push(currentTag);
    },
    setEditing(state: TagState, editing: boolean) {
      state.editing = editing;
    },
  },
};
export const actions: ActionTree<TagState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: TagContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.tags = s.tags;
      context.state.currentTag = s.currentTag;
      context.state.editing = s.editing;
      context.dispatch("resetListable");
    },
    async fetchList(context: TagContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await TagService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit("setTags", res.items.map(TagDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async deleteCurrentTag(context: TagContext): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("tagsList.deleteDialogTitle"),
          description: `${i18n.t("tagsList.deleteDescription")} "${
            context.state.currentTag.name
          }"`,
          callback: async () => {
            context.commit("setLoading", true);
            const tag = context.state.currentTag;
            if (tag && tag.id) {
              try {
                await TagService.delete(tag.id);
                context.commit("deleteTag", tag);
                context.commit(
                  "notifications/displayNotification",
                  {
                    message: i18n.t("tagsList.deleted"),
                    type: "success",
                  },
                  { root: true }
                );
              } catch (e) {
                logger.error(e);
              } finally {
                context.commit("setLoading", false);
                context.commit("setEditing", false);
              }
            }
          },
        },
        { root: true }
      );
    },

    async createTag(context: TagContext): Promise<void> {
      context.commit("setLoading", true);
      const tag = context.state.currentTag;
      if (tag) {
        try {
          const res = await TagService.post(tag.name);
          tag.id = res.data;
          context.dispatch("fetchList");
          // context.commit("addTag", tag);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("tagsList.created"),
              type: "success",
            },
            { root: true }
          );
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
          context.commit("setEditing", false);
        }
      }
    },

    async updateCurrentTag(context: TagContext): Promise<void> {
      context.commit("setLoading", true);
      const tag = context.state.currentTag;
      if (tag) {
        try {
          await TagService.put(tag.id as string, tag.name);
          context.commit("updateTag", tag);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("tagsList.updated"),
              type: "success",
            },
            { root: true }
          );
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
          context.commit("setEditing", false);
        }
      }
    },
  },
};
