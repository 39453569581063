



































































import Vue, { PropType, VueConstructor } from "vue";
import {
  License,
  licenseDetailRoomConfig,
  licenseDetailUserConfig,
} from "@/models/license";

import VaTableTop from "@/components/molecules/VaTableTop.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import dataTableMixin, {
  DataTableMixin,
} from "@/components/mixins/dataTableMixin";

export default (Vue as VueConstructor<DataTableMixin>).extend({
  name: "LicenseDevices",
  mixins: [dataTableMixin],
  components: {
    VaNoDataTag,
    VaTableTop,
  },
  data() {
    return {
      storeName: "licenseDevices",
      roomsConfig: licenseDetailRoomConfig,
      usersConfig: licenseDetailUserConfig,
    };
  },
  async beforeMount() {
    this.$store.commit("licenseDevices/setIsPod", this.license.isPod);
    this.$store.commit("licenseDevices/setLicenseId", this.license.id);
  },
  async beforeDestroy() {
    this.$store.commit("licenseDevices/reset");
  },
  props: {
    license: {
      type: Object as PropType<License>,
      required: true,
    },
  },
  computed: {
    devices(): any[] {
      return this.$store.getters["licenseDevices/devices"];
    },
  },
});
