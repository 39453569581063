import { AtmoDevicesResponseItemMeasurement } from "@/models/atmoDevice";

export default {
  buildDatasets(data: AtmoDevicesResponseItemMeasurement[]): any {
    const labelIaqi = data.map(({ dtm }) =>
      new Date(dtm).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
    const dataIaqi = data.map(({ iaqi }) => (iaqi.value ? iaqi.value : 0));
    return {
      labels: labelIaqi,
      datasets: [
        {
          label: "IAQI" as string,
          data: dataIaqi,
          backgroundColor: (ctx: any) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

            gradient.addColorStop(0.4, "rgba(101,163,13,0.6)");
            gradient.addColorStop(0.6, "rgba(234,179,8,0.84)");
            gradient.addColorStop(1, "rgba(239,68,68,0.6)");

            return gradient;
          },
          borderColor: (ctx: any) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

            gradient.addColorStop(0.4, "rgba(101,163,13,0.6)");
            gradient.addColorStop(0.6, "rgba(234,179,8,0.84)");
            gradient.addColorStop(1, "rgba(239,68,68,0.6)");

            return gradient;
          },
          fill: true,
        },
      ],
    };
  },
};

export interface ChartDataset {
  backgroundColor: string | Array<string>;
  borderColor?: string | Array<string>;
  data: Array<number>;
  label: string;
  borderWidth?: number;
  maxBarThickness?: number;
  fill?: boolean;
}

export interface ChartData {
  labels: Array<string>;
  datasets: Array<ChartDataset>;
}
