var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingInit
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "\n        d-flex\n        align-content-center\n        justify-center\n        text-center\n        align-center\n      ",
                staticStyle: { "min-height": "100vh" }
              },
              [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : !_vm.device
        ? _c("div", [_vm._m(0)])
        : _c(
            "div",
            [
              [
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c(
                      "v-tab-item",
                      { attrs: { value: "cards", transition: "false" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "pa-2", attrs: { "no-gutters": "" } },
                          _vm._l(_vm.measurementsListPublic, function(
                            measurement
                          ) {
                            return _c(
                              "v-col",
                              {
                                key: measurement.id,
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "4",
                                  lg: "4",
                                  xl: "3",
                                  xxl: "3"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ma-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleInfoDialog(
                                          true,
                                          measurement.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "mx-auto pt-2 px-2 pb-0",
                                        staticStyle: { "min-height": "170px" },
                                        attrs: { hover: "", link: "" }
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "10" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-h5 grey--text font-weight-bold",
                                                        staticStyle: {
                                                          "letter-spacing":
                                                            "-0.5px !important"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            measurement.title
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-end align-content-end",
                                                    attrs: { cols: "2" }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.calcScore(
                                                            measurement,
                                                            _vm.device
                                                              .latestMeasurement[
                                                              measurement.id
                                                            ]
                                                          )
                                                        ) + " "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "8" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-h3 text--primary font-weight-bold",
                                                        staticStyle: {
                                                          "letter-spacing":
                                                            "-1px !important"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.device
                                                                .latestMeasurement[
                                                                measurement.id
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "text-h5"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              measurement.suffix
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "text-right",
                                                    attrs: { cols: "4" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mt-2",
                                                        attrs: { size: "42" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            measurement.icon
                                                          ) + " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center grey--text text--darken-3 my-4"
                          },
                          [
                            _vm._v(
                              " Latest measurement: " +
                                _vm._s(
                                  _vm.device &&
                                    new Date(
                                      _vm.device.latestMeasurement.dtm
                                    ).toLocaleTimeString("en-US")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { attrs: { value: "history", transition: "false" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "ma-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-h4 font-weight-bold mb-2 py-2 text-center"
                                    },
                                    [_vm._v(" IAQI historical data ")]
                                  ),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _vm.atmoDataSets
                                            ? _c("atmo-chart", {
                                                attrs: {
                                                  data: _vm.atmoDataSets
                                                }
                                              })
                                            : _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("v-progress-circular", {
                                                    attrs: { indeterminate: "" }
                                                  })
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center grey--text text--darken-3 my-4"
                          },
                          [
                            _vm._v(
                              " Latest measurement: " +
                                _vm._s(
                                  _vm.device &&
                                    new Date(
                                      _vm.device.latestMeasurement.dtm
                                    ).toLocaleTimeString("en-US")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { attrs: { value: "about", transition: "false" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "ma-2" },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "rounded d-inline-block",
                                                            staticStyle: {
                                                              height: "auto",
                                                              "max-width":
                                                                "100%",
                                                              width: "100px"
                                                            },
                                                            attrs: {
                                                              src: require("@/assets/images/integrations/logo-atmo.jpg"),
                                                              alt: "ATMO"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c("v-col", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-h5 mb-1"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.device
                                                                    .name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " Atmocube device id: " +
                                                            _vm._s(
                                                              _vm.device.id
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: { cols: "3" }
                                                },
                                                [
                                                  _vm.device.status === "online"
                                                    ? _c("va-badge", {
                                                        attrs: {
                                                          text: "Online",
                                                          color: "green"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.device.status ===
                                                  "offline"
                                                    ? _c("va-badge", {
                                                        attrs: {
                                                          text: "Offline",
                                                          color: "red"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tabs",
                  {
                    staticStyle: {
                      position: "fixed",
                      bottom: "0",
                      border: "0",
                      "margin-bottom": "env(safe-area-inset-bottom)"
                    },
                    attrs: { centered: "", "icons-and-text": "" },
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c("v-tabs-slider"),
                    _c(
                      "v-tab",
                      { attrs: { href: "#cards" } },
                      [
                        _vm._v(" Live data "),
                        _c("va-icon", { attrs: { icon: "vi vi-app-store" } })
                      ],
                      1
                    ),
                    _c(
                      "v-tab",
                      { attrs: { href: "#history" } },
                      [
                        _vm._v(" Trend "),
                        _c("va-icon", {
                          attrs: { icon: "vi vi-chart-bar-33-1" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab",
                      { attrs: { href: "#about" } },
                      [
                        _vm._v(" About "),
                        _c("va-icon", { attrs: { icon: "vi vi-c-info" } })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            "max-width": "960",
            transition: "dialog-top-transition",
            "overlay-opacity": "0.9",
            "overlay-color": "#0a0f19"
          },
          model: {
            value: _vm.infoDialog,
            callback: function($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-12" },
            [
              _c(_vm.infoTemplates[_vm.currentTemplate], { tag: "component" }),
              _c(
                "v-card-actions",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "" },
                      on: {
                        click: function($event) {
                          return _vm.toggleInfoDialog(false)
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "\n        d-flex\n        align-content-center\n        justify-center\n        text-center\n        align-center\n      ",
        staticStyle: { "min-height": "100vh" }
      },
      [_c("p", [_vm._v("No associated sensors found.")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }