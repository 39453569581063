

















































































































































































































































import Vue, { Component } from "vue";
import IntegrationAtmoService from "@/services/integrationAtmoService";
import {
  AtmoDevicesResponseItem,
  AtmoDevicesResponseItemMeasurement,
  MeasurementListElement,
  measurementsListPublic,
} from "@/models/atmoDevice";
import VaBadge from "@/components/atoms/VaBadge.vue";
import AtmoChart from "@/components/pages/integrations/AtmoChart.vue";
import AtmoUtils, { ChartData } from "@/utils/atmoUtils";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import VaIcon from "@/components/atoms/VaIcon.vue";
import AtmoInfoIaqi from "@/components/pages/integrations/templates/AtmoInfoIaqi.vue";
import AtmoInfoCo2 from "@/components/pages/integrations/templates/AtmoInfoCo2.vue";
import AtmoInfoTemperature from "@/components/pages/integrations/templates/AtmoInfoTemperature.vue";
import AtmoInfoNoise from "@/components/pages/integrations/templates/AtmoInfoNoise.vue";
import AtmoInfoLight from "@/components/pages/integrations/templates/AtmoInfoLight.vue";
import AtmoInfoHumidity from "@/components/pages/integrations/templates/AtmoInfoHumidity.vue";
import AtmoInfoPm1 from "@/components/pages/integrations/templates/AtmoInfoPm1.vue";
import AtmoInfoPm25 from "@/components/pages/integrations/templates/AtmoInfoPm25.vue";
import AtmoInfoPm10 from "@/components/pages/integrations/templates/AtmoInfoPm10.vue";
import AtmoInfoPressure from "@/components/pages/integrations/templates/AtmoInfoPressure.vue";
import AtmoInfoVoc from "@/components/pages/integrations/templates/AtmoInfoVoc.vue";
import AtmoInfoCh2o from "@/components/pages/integrations/templates/AtmoInfoCh2o.vue";

export default Vue.extend({
  name: "PublicDashboardAtmo",
  components: { VaIcon, AtmoChart, VaBadge },
  data() {
    return {
      loadingInit: false,
      device: null as AtmoDevicesResponseItem | null,
      poller: 0,
      waiting: false,
      atmoDataSets: {} as ChartData,
      tab: "cards",
      infoDialog: false,
      currentTemplate: "iaqi",
      infoTemplates: {
        iaqi: AtmoInfoIaqi,
        co2: AtmoInfoCo2,
        temperature: AtmoInfoTemperature,
        noise: AtmoInfoNoise,
        light: AtmoInfoLight,
        humidity: AtmoInfoHumidity,
        pm1: AtmoInfoPm1,
        pm25: AtmoInfoPm25,
        pm10: AtmoInfoPm10,
        pressure: AtmoInfoPressure,
        voc: AtmoInfoVoc,
        ch2o: AtmoInfoCh2o,
      } as { [key: string]: Component },
    };
  },
  props: {
    publicDeviceId: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.attachPoller();
    this.$store.commit("session/setDarkMode", true);
  },
  beforeDestroy() {
    this.detachPoller();
  },
  async mounted() {
    if (this.publicDeviceId) {
      this.loadingInit = true;
      await IntegrationAtmoService.getPodAtmoDashboardPublic(
        this.publicDeviceId
      )
        .then((resp) => {
          this.device = resp;
          this.atmoDataSets = this.getAtmoDataSets(resp.measurements);
        })
        .finally(() => {
          this.loadingInit = false;
        });
    }
  },
  methods: {
    attachPoller() {
      this.poller = setInterval(async () => {
        if (!this.waiting) {
          await this.fetchData();
        }
      }, 60000);
    },
    detachPoller() {
      clearInterval(this.poller);
    },
    async fetchData(): Promise<void> {
      if (this.publicDeviceId) {
        this.waiting = true;
        await IntegrationAtmoService.getPodAtmoDashboardPublic(
          this.publicDeviceId
        )
          .then((resp) => {
            this.device = resp;
            this.getAtmoDataSets(resp.measurements);
          })
          .finally(() => {
            this.waiting = false;
          });
      }
    },
    calcScore(measurement: MeasurementListElement, value: number) {
      if (measurement.scores) {
        if (measurement.scores.invert) {
          switch (true) {
            case value < measurement.scores.step1:
              return "vi-atmo-score-4-fill green--text";
            case value >= measurement.scores.step1 &&
              value < measurement.scores.step2:
              return "vi-atmo-score-3-fill light-green--text";
            case value >= measurement.scores.step2 &&
              value < measurement.scores.step3:
              return "vi-atmo-score-2-fill orange--text";
            case value >= measurement.scores.step3:
              return "vi-atmo-score-1-fill red--text";
            default:
              return "";
          }
        } else {
          switch (true) {
            case value < measurement.scores.step1:
              return "vi-atmo-score-1-fill red--text";
            case value >= measurement.scores.step1 &&
              value < measurement.scores.step2:
              return "vi-atmo-score-2-fill orange--text";
            case value >= measurement.scores.step2 &&
              value < measurement.scores.step3:
              return "vi-atmo-score-3-fill light-green--text";
            case value >= measurement.scores.step3:
              return "vi-atmo-score-4-fill green--text";
            default:
              return "";
          }
        }
      } else {
        return "";
      }
    },
    getAtmoDataSets(data: AtmoDevicesResponseItemMeasurement[]): ChartData {
      return AtmoUtils.buildDatasets(data);
    },
    toggleInfoDialog(open: boolean, id: string | null = "") {
      if (open && id) {
        this.currentTemplate = id;
        this.infoDialog = true;
      } else {
        this.infoDialog = false;
      }
    },
  },
  computed: {
    AtmoInfoIaqi() {
      return AtmoInfoIaqi;
    },
    dataTableMixin() {
      return dataTableMixin;
    },
    measurementsListPublic(): MeasurementListElement[] {
      return measurementsListPublic;
    },
  },
});
