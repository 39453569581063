var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "va-title" }, [_vm._v("Integrations")]),
      _c(
        "v-card",
        { attrs: { loading: _vm.loading } },
        [
          _c("v-card-title", [
            _c("div", { staticClass: "va-title mb-0" }, [_vm._v("ATMO")])
          ]),
          _c("v-card-text", [
            _c("p", [
              _vm._v(
                " Here you can configure your ATMO devices. More information here: "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://atmotube.com/atmocube-support/cloud-dashboard-api",
                    target: "_blank",
                    title: "ATMO Support"
                  }
                },
                [_vm._v("ATMO Support page")]
              )
            ]),
            _vm.loading
              ? _c(
                  "div",
                  [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                  1
                )
              : _c("div", [
                  !_vm.APIKey
                    ? _c(
                        "div",
                        [
                          _c("v-divider"),
                          _c("p", { staticClass: "mt-4" }, [
                            _vm._v(
                              " No API Key set. Add an ATMO API Key to configure your ATMO devices. "
                            )
                          ]),
                          _c(
                            "v-form",
                            {
                              ref: "AtmoForm",
                              attrs: { onSubmit: "return false;" },
                              on: { submit: _vm.onUpdateParameters },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("va-text-field", {
                                staticClass: "mb-4",
                                attrs: {
                                  rules: [_vm.required],
                                  label: "API Key"
                                },
                                model: {
                                  value: _vm.newParameters.APIKey,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newParameters, "APIKey", $$v)
                                  },
                                  expression: "newParameters.APIKey"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "d-flex mt-5" },
                                [
                                  _c("va-button", {
                                    attrs: {
                                      submit: "",
                                      disabled: !_vm.valid,
                                      loading: _vm.loading,
                                      cta: _vm.$t("common.submit")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("va-text-field", {
                            staticClass: "mb-4",
                            attrs: { disabled: "", label: "API Key" },
                            model: {
                              value: _vm.APIKey,
                              callback: function($$v) {
                                _vm.APIKey = $$v
                              },
                              expression: "APIKey"
                            }
                          }),
                          _c("p", { staticClass: "mt-4" }, [
                            _vm._v(" An ATMO APY Key is set. "),
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.onResetApiKeyDialogOpen()
                                  }
                                }
                              },
                              [_vm._v("Click here")]
                            ),
                            _vm._v(" to reset. ")
                          ])
                        ],
                        1
                      )
                ])
          ])
        ],
        1
      ),
      _vm.APIKey
        ? _c(
            "v-card",
            { staticClass: "mt-4" },
            [
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    staticClass: "border-a pa-6 rounded",
                    attrs: {
                      headers: _vm.generateHeaders(_vm.tableConfig),
                      items: _vm.devices,
                      options: _vm.groupOptions,
                      "item-key": "id",
                      loading: _vm.associateLoading || _vm.unlinkLoading,
                      "loading-text": _vm.$t("common.loading"),
                      "mobile-breakpoint": "0"
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.groupOptions = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-data",
                          fn: function() {
                            return [_c("va-no-data-tag")]
                          },
                          proxy: true
                        },
                        {
                          key: "item.id",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item.id) + " ")]
                          }
                        },
                        {
                          key: "item.name",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item.name) + " ")]
                          }
                        },
                        {
                          key: "item.status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.status === "online"
                                ? _c(
                                    "div",
                                    [
                                      _c("va-badge", {
                                        attrs: {
                                          label: "Online",
                                          color: "green",
                                          text: "Online"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : item.status === "offline"
                                ? _c(
                                    "div",
                                    [
                                      _c("va-badge", {
                                        attrs: {
                                          label: "Offline",
                                          color: "red",
                                          text: "Offline"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _vm._v(" " + _vm._s(item.status) + " ")
                                  ])
                            ]
                          }
                        },
                        {
                          key: "item.pod",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.pod && item.pod.roomName
                                ? _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "/pub/d/atmo/" +
                                            item.pod.publicDeviceId,
                                          target: "_blank",
                                          title: "Public link"
                                        }
                                      },
                                      [
                                        _c("va-badge", {
                                          attrs: {
                                            text: item.pod.roomName,
                                            color: "green",
                                            "icon-class": "vi vi-c-check"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("va-badge", {
                                        attrs: {
                                          text: "Not associated",
                                          color: "red",
                                          "icon-class": "vi vi-warning-sign"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        },
                        {
                          key: "item.actions",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticStyle: { "min-width": "200px" } },
                                [
                                  _c("va-button", {
                                    staticClass: "mr-2",
                                    attrs: { cta: "Associate", size: "s" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onAssociateDialogOpen(item)
                                      }
                                    }
                                  }),
                                  item.pod
                                    ? _c("va-button", {
                                        attrs: {
                                          cta: "Unlink",
                                          size: "s",
                                          color: "red",
                                          type: "outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onUnlink(item)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2396986577
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "card", width: "700", scrollable: "" },
          model: {
            value: _vm.resetApiKeyDialog,
            callback: function($$v) {
              _vm.resetApiKeyDialog = $$v
            },
            expression: "resetApiKeyDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-6" },
            [
              _c("v-card-title", [_vm._v(" Are you sure?")]),
              _c("v-card-text", [
                _vm._v(
                  " Resetting the API Key will remove all device - room associations. This action cannot be undone. "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red" },
                      on: {
                        click: function($event) {
                          return _vm.onResetApiKey()
                        }
                      }
                    },
                    [_vm._v("Reset")]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.resetApiKeyDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "card", width: "700", scrollable: "" },
          model: {
            value: _vm.associateDialog,
            callback: function($$v) {
              _vm.associateDialog = $$v
            },
            expression: "associateDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                loading: _vm.associateLoading,
                disabled: _vm.associateLoading
              }
            },
            [
              _vm.associateLoading
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "text-center mx-auto px-6 py-14" },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "" }
                        })
                      ],
                      1
                    )
                  ])
                : _c(
                    "div",
                    [
                      _c("v-data-table", {
                        staticClass: "border-a pa-6 row-pointer rounded",
                        attrs: {
                          items: _vm.rooms,
                          headers: _vm.generateHeaders(
                            _vm.associateDialogTableConfig
                          ),
                          options: _vm.groupOptions,
                          "server-items-length":
                            _vm.$store.getters["room/totalItems"],
                          "item-key": "id",
                          loading: _vm.$store.getters["room/isLoading"],
                          "footer-props":
                            _vm.$store.getters["room/footerProps"],
                          "multi-sort": "",
                          "mobile-breakpoint": "0"
                        },
                        on: {
                          "update:options": function($event) {
                            _vm.groupOptions = $event
                          },
                          "click:row": function(item) {
                            return _vm.onAssociate(item)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "top",
                            fn: function() {
                              return [
                                _c("va-table-top", {
                                  attrs: { title: "Associate a room" },
                                  model: {
                                    value: _vm.searchString,
                                    callback: function($$v) {
                                      _vm.searchString = $$v
                                    },
                                    expression: "searchString"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "no-data",
                            fn: function() {
                              return [_c("va-no-data-tag")]
                            },
                            proxy: true
                          },
                          {
                            key: "item.roomName",
                            fn: function(ref) {
                              var item = ref.item
                              return [_vm._v(" " + _vm._s(item.name) + " ")]
                            }
                          },
                          {
                            key: "item.overallState",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("va-state-badge", {
                                  attrs: {
                                    size: "s",
                                    type: _vm.getStatus(item)
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "item.actions",
                            fn: function() {
                              return [
                                _c("va-button", { attrs: { cta: "Associate" } })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }