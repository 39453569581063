var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingInit
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "\n        d-flex\n        align-content-center\n        justify-center\n        text-center\n        align-center\n      ",
                staticStyle: { "min-height": "100vh" }
              },
              [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
              1
            )
          ])
        : !_vm.device
        ? _c("div", [_vm._m(0)])
        : _c(
            "div",
            [
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "pa-2", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-6 text-center",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("div", { staticClass: "text-h3" }, [
                                  _vm._v("Air quality and comfort")
                                ])
                              ]
                            ),
                            _vm._l(_vm.measurementsListPublic, function(
                              measurement
                            ) {
                              return _c(
                                "v-col",
                                {
                                  key: measurement.id,
                                  attrs: {
                                    cols: "12",
                                    xs: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "4",
                                    xl: "3",
                                    xxl: "3"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ma-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleInfoDialog(
                                            true,
                                            measurement.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mx-auto pt-2 px-2 pb-0",
                                          staticStyle: {
                                            "min-height": "170px"
                                          },
                                          attrs: { hover: "", link: "" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "10" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-h5 grey--text font-weight-bold",
                                                          staticStyle: {
                                                            "letter-spacing":
                                                              "-0.5px !important"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              measurement.title
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end align-content-end",
                                                      attrs: { cols: "2" }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.calcScore(
                                                              measurement,
                                                              _vm.device
                                                                .latestMeasurement[
                                                                measurement.id
                                                              ]
                                                            )
                                                          ) + " "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: measurement.icon
                                                          ? 8
                                                          : 12
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-h3 text--primary font-weight-bold",
                                                          staticStyle: {
                                                            "letter-spacing":
                                                              "-1px !important"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.device
                                                                  .latestMeasurement[
                                                                  measurement.id
                                                                ]
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "text-h5"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                measurement.suffix
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  measurement.icon
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                size: "42"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  measurement.icon
                                                                ) + " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-0 px-4" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pt-0 pr-2 pr-xs-3",
                                attrs: {
                                  cols: "6",
                                  xs: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "6",
                                  xl: "6",
                                  xxl: "6"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-2 ma-0" },
                                  [
                                    _c(
                                      "v-card",
                                      {},
                                      [
                                        _c("v-card-text", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { height: "324px" }
                                            },
                                            [
                                              _vm.atmoDataSets
                                                ? _c("atmo-chart", {
                                                    staticStyle: {
                                                      height: "324px"
                                                    },
                                                    attrs: {
                                                      data: _vm.atmoDataSets
                                                    }
                                                  })
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            indeterminate: ""
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pt-0 pl-2 pl-xs-3",
                                attrs: {
                                  cols: "6",
                                  xs: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "6",
                                  xl: "6",
                                  xxl: "6"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-2 pa-0" },
                                  [
                                    _c(
                                      "v-card",
                                      {},
                                      [
                                        _c("v-card-text", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { height: "324px" }
                                            },
                                            [
                                              _c("atmo-temperature-humidity", {
                                                staticStyle: {
                                                  height: "324px"
                                                },
                                                attrs: {
                                                  temperature:
                                                    _vm.device.latestMeasurement
                                                      .temperature,
                                                  humidity:
                                                    _vm.device.latestMeasurement
                                                      .humidity
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "align-center d-flex flex-column pt-11",
                        attrs: { cols: "6" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-h3 text-center pb-12" },
                          [_vm._v("Screen sharing")]
                        ),
                        _c("img", {
                          staticClass: "mb-8",
                          staticStyle: { width: "100%", "max-width": "200px" },
                          attrs: {
                            src: require("@/assets/images/integrations/gfx-screensharing.svg"),
                            alt: "Screen sharing"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "mb-4",
                            staticStyle: { width: "80%" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pa-8" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "75px"
                                        },
                                        attrs: {
                                          src: require("@/assets/images/integrations/wifi.svg"),
                                          alt: "WiFi"
                                        }
                                      })
                                    ]),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "10" } },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "sscard-title text-h4"
                                                  },
                                                  [_vm._v("WiFi")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "sscard-title text-h4"
                                                  },
                                                  [_vm._v("Password")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "sscard-title text-h4"
                                                  },
                                                  [_vm._v("Beeah Guest")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "sscard-title text-h4"
                                                  },
                                                  [_vm._v("B33@H@786")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "mb-4",
                            staticStyle: { width: "80%" }
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pa-8" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "75px"
                                        },
                                        attrs: {
                                          src: require("@/assets/images/integrations/windows.svg"),
                                          alt: "Windows"
                                        }
                                      })
                                    ]),
                                    _c("v-col", { attrs: { cols: "10" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "sscard-title text-h4" },
                                        [_vm._v("Windows - Miracast")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "sscard-text" },
                                        [_vm._v("• Press WIN + K")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "sscard-text" },
                                        [
                                          _vm._v(
                                            '• Select "Rimal Meeting Room"'
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "mb-4",
                            staticStyle: { width: "80%" }
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pa-8" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "75px"
                                        },
                                        attrs: {
                                          src: require("@/assets/images/integrations/apple.svg"),
                                          alt: "Apple"
                                        }
                                      })
                                    ]),
                                    _c("v-col", { attrs: { cols: "10" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "sscard-title text-h4" },
                                        [_vm._v("Apple - AirPlay")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "sscard-text" },
                                        [_vm._v("• Connect to the room Wi-Fi")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "sscard-text" },
                                        [
                                          _vm._v(
                                            " • Control Center -> Screen Mirroring "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "mb-4",
                            staticStyle: { width: "80%" }
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pa-8" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "75px"
                                        },
                                        attrs: {
                                          src: require("@/assets/images/integrations/android.svg"),
                                          alt: "Android"
                                        }
                                      })
                                    ]),
                                    _c("v-col", { attrs: { cols: "10" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "sscard-title text-h4" },
                                        [_vm._v(" Android - Google Cast ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "sscard-text" },
                                        [_vm._v("• Connect to the room Wi-Fi")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "sscard-text" },
                                        [
                                          _vm._v(
                                            " • Quick Settings -> Cast Screen "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            "max-width": "960",
            transition: "dialog-top-transition",
            "overlay-opacity": "0.9",
            "overlay-color": "#0a0f19"
          },
          model: {
            value: _vm.infoDialog,
            callback: function($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-12" },
            [
              _c(_vm.infoTemplates[_vm.currentTemplate], { tag: "component" }),
              _c(
                "v-card-actions",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "" },
                      on: {
                        click: function($event) {
                          return _vm.toggleInfoDialog(false)
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "\n        d-flex\n        align-content-center\n        justify-center\n        text-center\n        align-center\n      ",
        staticStyle: { "min-height": "100vh" }
      },
      [_c("p", [_vm._v("No associated sensors found.")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }