var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { attrs: { id: "license-detail" } },
        [
          _c(
            "div",
            { staticClass: "va-title d-flex align-self-center" },
            [
              _c("va-back", {
                attrs: {
                  target: "/licenses",
                  tooltip: _vm.$t("licenseDetail.back")
                }
              }),
              _c("span", { staticClass: "ml-4" }, [
                _vm._v(" " + _vm._s(_vm.$t("common.license")) + " "),
                _vm.license
                  ? _c("span", [_vm._v("- " + _vm._s(_vm.license.name))])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm.license
            ? _c(
                "va-card",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("div", { staticClass: "va-subtitle-s" }, [
                            _vm._v(_vm._s(_vm.$t("common.licenseInfo")))
                          ]),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.name") } },
                            [_vm._v(" " + _vm._s(_vm.license.name) + " ")]
                          ),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.key") } },
                            [_vm._v(" " + _vm._s(_vm.license.key) + " ")]
                          ),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.sku") } },
                            [_vm._v(" " + _vm._s(_vm.license.sku) + " ")]
                          ),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.expireAt") } },
                            [
                              _vm.license.licenseIsPerpetual
                                ? _c("va-badge", {
                                    attrs: {
                                      size: "s",
                                      text: _vm.$t("licensesList.perpetual"),
                                      color: "success"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date2string")(
                                          _vm.license.expirationDate
                                        )
                                      )
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("div", { staticClass: "va-subtitle-s" }, [
                            _vm._v(_vm._s(_vm.$t("common.licenseStatus")))
                          ]),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.status") } },
                            [_vm._v(" " + _vm._s(_vm.license.status) + " ")]
                          ),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.activations") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.license.activations.current) +
                                  " / " +
                                  _vm._s(_vm.license.activations.max) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "va-info-row",
                            { attrs: { label: _vm.$t("common.roomLicense") } },
                            [
                              _c("va-icon", {
                                attrs: {
                                  icon: "vi vi-shape-oval",
                                  color: _vm.license.isPod ? "success" : "error"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "va-info-row",
                            {
                              attrs: {
                                label: _vm.$t("common.maintenanceExpireAt")
                              }
                            },
                            [
                              _vm.license.maintenanceIsPerpetual
                                ? _c("va-badge", {
                                    attrs: {
                                      size: "s",
                                      text: _vm.$t("licensesList.perpetual"),
                                      color: "success"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date2string")(
                                          _vm.license.maintenanceExpirationDate
                                        )
                                      )
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("license-devices", { attrs: { license: _vm.license } })
                ],
                1
              )
            : _vm._e(),
          !_vm.isProduction
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "va-subtitle class mt-6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("licenseDetail.actions")) + " ")
                  ]),
                  _c("va-card", [
                    _c("div", { staticClass: "text-right" }),
                    _c(
                      "div",
                      { staticClass: "text-right mt-4" },
                      [
                        _vm._v(" DEV only operations: "),
                        _c("va-button", {
                          attrs: {
                            size: "s",
                            loading: _vm.$store.getters["license/loading"],
                            cta: _vm.$t("licenseDetail.deleteLicense"),
                            color: "red",
                            type: "outline"
                          },
                          on: { click: _vm.deleteLicense }
                        }),
                        _c("va-button", {
                          staticClass: "mx-4",
                          attrs: {
                            size: "s",
                            loading: _vm.$store.getters["license/loading"],
                            cta: _vm.$t("licenseDetail.clearSeats"),
                            color: "red",
                            type: "outline"
                          },
                          on: { click: _vm.clearSeats }
                        }),
                        _c("va-button", {
                          attrs: {
                            size: "s",
                            loading: _vm.$store.getters["license/loading"],
                            cta: _vm.$t("licenseDetail.clearExtensions"),
                            color: "red",
                            type: "outline"
                          },
                          on: { click: _vm.clearExtensions }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }