import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Policy, possibleGroups, PoliciesGroups } from "@/models/policy";
import * as listable from "./listableModule";
import logger from "@/services/loggerService";
import PolicyService from "@/services/policyService";
import i18n from "@/i18n";
import router from "@/router";
import cloneDeep from "lodash/cloneDeep";

export interface PolicyState extends listable.ListableState {
  policies: Policy[];
  currentPolicy: Policy | undefined;
  currentGroup: PoliciesGroups | undefined;
  groupsList: string[];
  hasBeenChanged: boolean;
}

type PolicyContext = ActionContext<PolicyState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    policies: [],
    // currentPolicy: clone(emptyPolicy),
    currentPolicy: undefined,
    currentGroup: undefined,
    groupsList: possibleGroups,
    hasBeenChanged: false,
  },
};

export const state = (): PolicyState => cloneDeep(initialState);

export const getters: GetterTree<PolicyState, RootState> = {
  ...listable.getters,
  ...{
    policies: (state): Policy[] => {
      return state.policies;
    },
    currentPolicy: (state): Policy | undefined => {
      return state.currentPolicy;
    },
    currentGroup: (state): PoliciesGroups | undefined => {
      return state.currentGroup;
    },
    groupsList: (state): PoliciesGroups[] => {
      return state.groupsList;
    },
    hasBeenChanged: (state): boolean => {
      return state.hasBeenChanged;
    },
  },
};

export const mutations: MutationTree<PolicyState> = {
  ...listable.mutations,
  ...{
    setChanged(state: PolicyState, changed: boolean) {
      state.hasBeenChanged = changed;
    },
    setPolicies(state: PolicyState, policies: Policy[]) {
      state.policies = policies;
    },
    setCurrentPolicy(state: PolicyState, policy: Policy) {
      state.currentGroup = policy.type;
      state.currentPolicy = policy;
    },
    setCurrentGroup(state: PolicyState, group: PoliciesGroups) {
      state.currentGroup = group;
      // state.currentPolicy = clone(emptyPolicy);
      // state.currentPolicy.type = group;
    },
  },
};

export const actions: ActionTree<PolicyState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: PolicyContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.currentPolicy = s.currentPolicy;
      context.state.currentGroup = s.currentGroup;
      context.state.groupsList = s.groupsList;
      context.state.hasBeenChanged = s.hasBeenChanged;
      context.dispatch("resetListable");
    },
    async fetchList(context: PolicyContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await PolicyService.search(
          context.state.queryOptions,
          context.state.searchString,
          context.state.currentGroup
          // context.state.currentPolicy.type
        );
        context.commit("setPolicies", res.items);
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchDetail(context: PolicyContext, id: string): Promise<void> {
      context.commit("setLoading", true);
      try {
        const policy = await PolicyService.get(id);
        context.commit("setCurrentPolicy", policy);
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
    },
    async savePolicy(context: PolicyContext, policy: Policy): Promise<void> {
      context.commit("setLoading", true);
      // const policy = clone(context.state.currentPolicy);
      try {
        if (policy) {
          let id = policy?.id;
          const cleanPolicy = PolicyService.clearPolicyFields(policy);
          if (id) {
            //update policy
            await PolicyService.put(cleanPolicy);
          } else {
            //new policy
            id = await PolicyService.post(cleanPolicy);
          }
          //force fetch policy according to backend manipulations
          context.dispatch("fetchDetail", id);
          router.push(`/rooms/policies/${id}`);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("policiesList.updated"),
              type: "success",
            },
            { root: true }
          );
        }
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
    },
    async deleteCurrentPolicy(context: PolicyContext): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("policiesList.deleteDialogTitle"),
          description: `${i18n.t("policiesList.deleteDescription")}`,
          callback: async () => {
            context.commit("setLoading", true);
            const policy = context.state.currentPolicy;
            if (policy?.id) {
              try {
                await PolicyService.delete(policy.id);
                router.push(`/rooms/policies`);
              } catch (e) {
                logger.error(e);
              } finally {
                context.commit("setLoading", false);
              }
            }
          },
        },
        { root: true }
      );
    },
  },
};
