<script>
import { Scatter } from "vue-chartjs";
import Vue from "vue";

const dataLabelInsteadOfPoint = {
  id: "dataLabelInsteadOfPoint",
  afterDatasetsDraw(chart) {
    for (const i in chart.data.datasets) {
      const dataset = chart.data.datasets[i];
      if (dataset.dataLabels) {
        for (const j in dataset.data) {
          const model =
            dataset._meta[Object.keys(dataset._meta)[0]].data[j]._model;
          const ctx = chart.ctx;
          ctx.save();
          ctx.textAlign = dataset.data[j].horizontalAlign || "center";
          ctx.textBaseline = dataset.data[j].verticalAlign || "middle";
          ctx.fillStyle = dataset.dataLabels.color || "black";
          ctx.font = dataset.dataLabels.font || "12px Arial";
          ctx.fillText(dataset.data[j].label, model.x, model.y);
          ctx.restore();
        }
      }
    }
  },
};

export default Vue.extend({
  extends: Scatter,
  props: {
    temperature: {
      type: Number,
      required: false,
    },
    humidity: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            label: "Humidity & Temperature",
            data: [{ x: this.humidity, y: this.temperature }],
            backgroundColor: ["rgba(255, 26, 104, 1)"],
            borderColor: ["rgba(255, 26, 104, 1)"],
            borderWidth: 1,
            showLine: true,
            tension: 0,
            fill: true,
            pointStyle: "circle",
            pointRadius: 8,
            pointHoverRadius: 15,
          },
          {
            data: [
              {
                x: 85,
                y: 22,
                label: "Humid",
                verticalAlign: "middle",
                horizontalAlign: "right",
              },
              {
                x: 16,
                y: 22,
                label: "Dry",
                verticalAlign: "middle",
                horizontalAlign: "left",
              },
              {
                x: 50,
                y: 40,
                label: "Hot",
                verticalAlign: "top",
                horizontalAlign: "center",
              },
              {
                x: 50,
                y: 5,
                label: "Cold",
                verticalAlign: "bottom",
                horizontalAlign: "center",
              },
              {
                x: 50,
                y: 22,
                label: "Comfort Zone",
                verticalAlign: "middle",
                horizontalAlign: "center",
              },
            ],
            dataLabels: {
              color: "rgb(139,139,139)",
              font: "12px Arial",
            },
            backgroundColor: ["rgb(139,139,139)"],
            borderWidth: 0,
            showLine: false,
            fill: false,
            pointRadius: 0,
          },
          {
            // label: "Comfort zone",
            data: [
              { x: 30, y: 22 },
              { x: 32, y: 26 },
              { x: 34, y: 27 },
              { x: 50, y: 28 },
              { x: 66, y: 27 },
              { x: 68, y: 26 },
              { x: 70, y: 22 },
              { x: 68, y: 18 },
              { x: 66, y: 17 },
              { x: 50, y: 16 },
              { x: 34, y: 17 },
              { x: 32, y: 18 },
              { x: 30, y: 22 },
            ],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
            showLine: true,
            tension: 0,
            fill: true,
          },
        ],
      },
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: this.$vuetify.theme.currentTheme.chartText,
            filter: function (item) {
              return !!item.text;
            },
          },
        },
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                min: 5,
                max: 40,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                min: 15,
                max: 85,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5,
          },
        },

        // stepped: 1,
        borderDash: [],
        spanGaps: true,
        tooltips: {
          enabled: false,
          mode: "point",
          intersect: false,
          position: "average",
        },
      },
    };
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
  mounted() {
    this.addPlugin(dataLabelInsteadOfPoint);
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    temperature: function (val, oldVal) {
      console.log("temperature", val);
      console.log("temperatureOld", oldVal);
      this.chartData.datasets[0].data[0].y = this.temperature;
      this.$data._chart.update();
    },
    humidity: function (val, oldVal) {
      console.log("humidity", val);
      console.log("humidityOld", oldVal);
      this.chartData.datasets[0].data[0].x = this.humidity;
      this.$data._chart.update();
    },
  },
});
</script>

<style lang="scss" scoped></style>
