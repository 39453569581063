



















































































import Vue from "vue";
import { UserList } from "@/models/user";
import { HeaderTable, QueryTable } from "@/models/table";
import UserService from "@/services/userService";
import TableUtil from "@/utils/tableUtil";
import debounce from "lodash/debounce";
import VaBack from "@/components/molecules/VaBack.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import router from "@/router";

export default Vue.extend({
  name: "UsersList",
  components: { MainColumns, VaButton, VaCard, VaBack, Notifications },
  props: {
    userGroupId: {
      type: String,
      required: false,
    },
  },
  beforeMount() {
    if (
      router.currentRoute.query?.prev == "license" &&
      router.currentRoute.query?.id
    ) {
      this.backLink =
        "/licenses/" + router.currentRoute.query.id + "?preserveQuery=true";
    }
    const user = this.$store.getters["users/currentUser"];
    if (!user) {
      this.runQuery();
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("common.email"),
          value: "email",
          sortable: false,
          filterable: true,
        },
        {
          text: this.$i18n.t("common.actions"),
          value: "actions",
          sortable: false,
          filterable: false,
          width: 100,
        },
      ] as HeaderTable[],
      loading: false,
      saving: false,
      deleting: false,
      update: true,
      items: [] as Array<UserList>,
      backLink: "/users",
    };
  },
  methods: {
    async onSave(): Promise<void> {
      this.saving = true;
      try {
        await UserService.update(this.user);
        this.$notification.success(this.$t("usersList.updated"));
        await this.runQuery();
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "UsersList", "onSave");
      } finally {
        this.saving = false;
      }
    },
    async onDelete(): Promise<void> {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: this.$i18n.t("usersList.deleteDialogTitle"),
        description: `${this.$i18n.t("usersList.deleteDescription")}`,
        callback: async () => {
          this.deleting = true;
          try {
            await UserService.delete(this.user.userId);
            this.$notification.success(this.$t("usersList.deleted"));
            this.$router.push(`/users`);
          } catch (e) {
            this.$notification.error(e);
            this.$sentry.capture(e, "UsersList", "onDelete");
          } finally {
            this.deleting = false;
          }
        },
      });
    },
    async runQuery(): Promise<void> {
      this.loading = true;
      const id = this.$route.params.id as string;
      let query: QueryTable = {
        limit: 1,
        page: 1,
        sortBy: [],
        filterBy: TableUtil.buildFilterBy("", this.headers, [
          { values: [id], field: "id" },
        ]),
      } as QueryTable;
      try {
        const res = await UserService.search(query);
        this.$store.commit("users/setCurrentUser", res.items[0]);
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "UsersList", "runQuery");
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    user(): UserList {
      return this.$store.getters["users/currentUser"];
    },
  },
  watch: {
    search: debounce(function (this: any) {
      this.runQuery();
    }, 1500),
  },
});
