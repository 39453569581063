
























import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { License, LicenseData, licenseConfig } from "@/models/license";
import VaButton from "@/components/atoms/VaButton.vue";
import Notifications from "@/components/organisms/Notifications.vue";

export default Vue.extend({
  name: "IntegrationsList",
  components: {
    VaButton,
    MainColumns,
    Notifications,
  },
  data() {
    return {
      integrations: ["atmo", "other"],
      storeName: "license",
      dialog: false,
      newLicense: {
        licenseKey: "",
        isRenewal: false,
      } as LicenseData,
      tableConfig: licenseConfig,
      fileLicense: undefined,
    };
  },
  methods: {
    async onUpload(): Promise<void> {
      await this.$store.dispatch("onprem/uploadLicense", this.fileLicense);
      this.dialog = false;
      this.fileLicense = undefined;
    },

    async onSave(): Promise<void> {
      if (
        (
          this.$refs.newLicenseForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        this.dialog = false;
        await this.$store.dispatch("license/addLicense", this.newLicense);
        this.newLicense = {
          licenseKey: "",
          isRenewal: false,
        };
      }
    },
    statusColor(license: License): string {
      switch (license.status) {
        case "active":
          return "success";
        case "inactive":
          return "error";
        case "expired":
          return "secondary";
        default:
          return "secondary";
      }
    },
  },
  computed: {
    isOnPrem(): boolean {
      return this.$store.getters["onprem/isOnPrem"];
    },
  },
});
