import i18n from "@/i18n";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";

export enum LicenseStatus {
  INACTIVE,
  ACTIVE,
  EXPIRED,
}

export const LICENSES: Array<any> = [
  {
    status: LicenseStatus.INACTIVE,
    label: i18n.t("common.inactive") as string,
    color: "error",
  },
  {
    status: LicenseStatus.ACTIVE,
    label: i18n.t("common.active") as string,
    color: "success",
  },
  {
    status: LicenseStatus.EXPIRED,
    label: i18n.t("common.expired") as string,
    color: "secondary",
  },
];

export interface LicenseActivations {
  max: number;
  current: number;
}

export interface LicenseData {
  licenseKey: string;
  isRenewal?: boolean;
  licenseKeyToRenew?: string;
}

export interface LicenseUser {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  creationDate: number;
}

export interface LicenseRoomUser {
  deviceId: string;
  deviceName: string;
  deviceVersion: string;
  deviceLanguage: string;
  creationDate: number;
}

export interface LicenseDetail {
  //used by "add new user window" that needs a dedicated license item
  key: string;
  name: string;
  sku: string;
  status: LicenseStatus;
  creationDate: number;
  expirationDate: number;
  isRoom: boolean;
  activations: LicenseActivations;
  users: Array<any>;
  isPod: boolean;
}

// export interface LicenseList {
//   key: string;
//   name: string;
//   sku: string;
//   status: LicenseStatus;
//   creationDate: number;
//   expirationDate: number | null;
//   maxUsers: number;
//   currentUsers: number;
//   licensePackageId: string;
//   isRoom: boolean;
//   activations: LicenseActivations;
//   users: Array<any>;
// }

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): LicenseDetail {
    return {
      key: input.key,
      name: input.name,
      sku: input.sku,
      status: input.status,
      creationDate: input.creationDate,
      expirationDate: input.expirationDate,
      isRoom: input.isPod,
      activations: input.activations,
      isPod: input.isPod,
    } as LicenseDetail;
  },
  serialize(): any {
    return {};
  },
  // serialize(input: LicenseDetail): any {
  //   return {};
  // },
};

/** ------------ new models ------------ **/
const possibleStatus = ["inactive", "active", "expired"];
//TODO REMOVE ME
const LicenseStatusDecoder = possibleStatus.reduce((acc, elem, index) => {
  return { ...acc, [elem]: index };
}, possibleStatus);
export type LicenseStatuses = typeof possibleStatus[number];

const possibleTypes = ["pod", "enterprise", "app"];
export type LicenseTypes = typeof possibleTypes[number];

export interface LicenseDetailUser {
  id: string; //userId
  email: string;
  firstName: string;
  lastName: string;
  creationDate: Date;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function LicenseDetailUserDeserializer(input: any): LicenseDetailUser {
  return {
    id: input.userId,
    email: input.email,
    firstName: input.firstName,
    lastName: input.lastName,
    creationDate: new Date(input.creationDate),
  };
}

export interface LicenseDetailRoom {
  id: string;
  podId: string;
  name: string;
  version: string;
  language: string;
  creationDate: Date;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function LicenseDetailRoomDeserializer(input: any): LicenseDetailRoom {
  return {
    id: input.deviceId,
    podId: input.podId,
    name: input.deviceName,
    version: input.deviceVersion,
    language: input.deviceLanguage,
    creationDate: new Date(input.creationDate),
  };
}

export interface License {
  id?: string;
  key: string;
  name: string;
  sku: string;
  status: LicenseStatuses;
  creationDate: Date;
  expirationDate?: Date;
  maintenanceExpirationDate?: Date;
  // type: LicenseTypes;
  isPod: boolean;
  activations: {
    max: number;
    current: number;
  };
  detailUsers: LicenseDetailUser[];
  detailRooms: LicenseDetailRoom[];
  licenseIsPerpetual: boolean;
  maintenanceIsPerpetual: boolean;
}

export const licenseConfig = {
  key: {
    dtoName: "[key]", //TODO BAD API NAME FIX ON BACKEND
    sortable: false,
    filterable: true,
    label: i18n.t("common.key"),
  },
  status: {
    dtoName: "status",
    sortable: false,
    filterable: false,
    label: i18n.t("common.status"),
  },
  type: {
    dtoName: "isPod",
    sortable: false,
    filterable: false,
    label: i18n.t("common.type"),
  },
  expirationDate: {
    dtoName: "expirationDate",
    sortable: false,
    filterable: false,
    label: i18n.t("common.expirationDate"),
  },
  maintenanceExpirationDate: {
    dtoName: "maintenanceExpirationDate",
    sortable: false,
    filterable: false,
    label: i18n.t("licenseDetail.maintenanceExp"),
  },
  activations: {
    dtoName: "maxUsers",
    sortable: false,
    filterable: false,
    label: i18n.t("common.users"),
  },
} as TableConfig;

export function LicenseSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, licenseConfig),
    filterBy: { values: FilterBySerializer(licenseConfig, searchString) },
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function LicenseDeserializer(input: any): License {
  return {
    id: input.key, //licensePackageId,
    key: input.key,
    name: input.name,
    sku: input.sku,
    status: LicenseStatusDecoder[input.status],
    creationDate: new Date(input.creationDate),
    expirationDate: input?.expirationDate
      ? new Date(input.expirationDate)
      : undefined,
    maintenanceExpirationDate: input?.maintenanceExpirationDate
      ? new Date(input.maintenanceExpirationDate)
      : undefined,
    // type: input?.sku?.includes("VAL-ENT") ? "app" : "pod", //TODO FETCH FORM API
    isPod: input.isPod || false,
    activations: {
      max: input?.activations?.max || 0,
      current: input?.activations?.current || 0,
    },
    detailUsers: [],
    detailRooms: [],
    licenseIsPerpetual: input.licenseIsPerpetual || false,
    maintenanceIsPerpetual: input.maintenanceIsPerpetual || false,
  };
}

export const licenseDetailUserConfig = {
  email: {
    dtoName: "email",
    sortable: false,
    filterable: true,
    label: i18n.t("common.email"),
  },
  creationDate: {
    dtoName: "expirationDate",
    sortable: false,
    filterable: false,
    label: i18n.t("common.creationDate"),
  },
} as TableConfig;

export function LicenseDetailUserSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, licenseDetailUserConfig),
    filterBy: {
      values: FilterBySerializer(licenseDetailUserConfig, searchString),
    },
  };
}

export const licenseDetailRoomConfig = {
  id: {
    dtoName: "deviceId",
    sortable: false,
    filterable: true,
    label: i18n.t("common.deviceId"),
  },
  name: {
    dtoName: "deviceName",
    sortable: false,
    filterable: true,
    label: i18n.t("common.deviceName"),
  },
  version: {
    dtoName: "deviceVersion",
    sortable: false,
    filterable: true,
    label: i18n.t("common.deviceVersion"),
  },
  creationDate: {
    dtoName: "expirationDate",
    sortable: false,
    filterable: false,
    label: i18n.t("common.creationDate"),
  },
} as TableConfig;

export function LicenseDetailRoomSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, licenseDetailRoomConfig),
    filterBy: {
      values: FilterBySerializer(licenseDetailRoomConfig, searchString),
    },
  };
}
