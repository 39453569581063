



















import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: { VaIcon },
  props: {
    target: {
      type: String,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
  },
});
