
















































































































































































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import IntegrationAtmoService from "@/services/integrationAtmoService";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaBadge from "@/components/atoms/VaBadge.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import {
  associateDialogRoomConfig,
  AtmoDevice,
  atmoDeviceConfig,
} from "@/models/atmoDevice";
import {
  RoomDetail,
  RoomOverallStateData,
  RoomOverallStatesData,
} from "@/models/room";
import VaStateBadge from "@/components/molecules/VaStateBadge.vue";

export default Vue.extend({
  name: "Atmo",
  components: {
    VaStateBadge,
    VaNoDataTag,
    VaTableTop,
    VaBadge,
    VaButton,
    VaTextField,
    MainColumns,
    Notifications,
  },
  mixins: [errorMixin, validationMixin, dataTableMixin],
  data() {
    return {
      storeName: "room",
      loading: true,
      APIKey: "",
      devices: [] as AtmoDevice[],
      newParameters: {
        APIKey: "",
      },
      tableConfig: atmoDeviceConfig,
      associateLoading: false,
      associateDialog: false,
      associateDialogDeviceId: "",
      associateDialogTableConfig: associateDialogRoomConfig,
      unlinkLoading: false,
      resetApiKeyDialog: false,
      overallStatesData: RoomOverallStatesData as Array<RoomOverallStateData>,
    };
  },
  async mounted() {
    await IntegrationAtmoService.getParameters()
      .then(async (resp) => {
        const APIKey = await resp.find((x: any) => x.parameterName === "APIKey")
          ?.parameterValue;
        if (APIKey) {
          this.onApiKeyAdded(APIKey);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    async onUpdateParameters(): Promise<void> {
      if (
        (this.$refs.AtmoForm as Vue & { validate: () => boolean }).validate()
      ) {
        await IntegrationAtmoService.updateParameters(this.newParameters)
          .then(async () => {
            this.$store.commit("notifications/displayNotification", {
              message: "API Key added successfully.",
              type: "success",
            });
            this.onApiKeyAdded(this.newParameters.APIKey);
          })
          .catch(() => {
            this.$store.commit("notifications/displayNotification", {
              message: "Error adding API Key.",
              type: "error",
            });
          })
          .finally(() => {
            this.associateLoading = false;
          });
      }
    },
    async onResetApiKeyDialogOpen(): Promise<void> {
      this.resetApiKeyDialog = true;
    },
    async onResetApiKey(): Promise<void> {
      this.loading = true;
      await IntegrationAtmoService.updateParameters({
        APIKey: "",
      })
        .then(async () => {
          this.onApiKeyRemoved();
          this.$store.commit("notifications/displayNotification", {
            message: "API Key reset successfully.",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.commit("notifications/displayNotification", {
            message: "Error resetting API Key.",
            type: "error",
          });
        })
        .finally(() => {
          this.resetApiKeyDialog = false;
          this.loading = false;
        });
    },
    async onApiKeyAdded(APIKey: string) {
      this.APIKey = APIKey;
      await IntegrationAtmoService.getDevices()
        .then((resp) => {
          this.devices = resp;
        })
        .catch(() => {
          this.$store.commit("notifications/displayNotification", {
            message:
              "Error getting the list of devices. Make sure the API Key is correct.",
            type: "error",
          });
        });
    },
    async onApiKeyRemoved() {
      this.APIKey = "";
      this.newParameters.APIKey = "";
      this.devices = [];
    },
    async onAssociateDialogOpen(item: any): Promise<void> {
      this.associateDialogDeviceId = item.id;
      this.associateDialog = true;
      this.$store.dispatch("room/fetchList");
    },
    async onAssociate(podItem: any): Promise<void> {
      this.associateLoading = true;
      await IntegrationAtmoService.updateDevicePodRelation(
        this.associateDialogDeviceId,
        podItem.id
      )
        .then(async () => {
          this.associateDialog = false;
          this.devices = await IntegrationAtmoService.getDevices();
          this.$store.commit("notifications/displayNotification", {
            message: "Device associated successfully.",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.commit("notifications/displayNotification", {
            message: "Error associating device.",
            type: "error",
          });
        })
        .finally(() => {
          this.associateLoading = false;
        });
    },
    async onUnlink(item: any): Promise<void> {
      this.unlinkLoading = true;
      await IntegrationAtmoService.deleteDevicePodRelation(item.id)
        .then(async () => {
          this.associateDialog = false;
          this.devices = await IntegrationAtmoService.getDevices();
          this.$store.commit("notifications/displayNotification", {
            message: "Device unlinked successfully.",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.commit("notifications/displayNotification", {
            message: "Error unlinking device.",
            type: "error",
          });
        })
        .finally(() => {
          this.unlinkLoading = false;
        });
    },
    getStatus(room: RoomDetail) {
      return this.overallStatesData.find((stateData) => {
        return room.overallState === stateData.id;
      })?.name;
    },
  },
  computed: {
    rooms(): RoomDetail[] {
      const rooms = this.$store.getters["room/rooms"];
      return rooms.filter((room: RoomDetail) => {
        return !!room.name;
      });
    },
    isOnPrem(): boolean {
      return this.$store.getters["onprem/isOnPrem"];
    },
  },
});
