var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "mt-6",
        attrs: {
          headers: _vm.license.isPod
            ? _vm.generateHeaders(_vm.roomsConfig, false)
            : _vm.generateHeaders(_vm.usersConfig),
          items: _vm.devices,
          options: _vm.groupOptions,
          "server-items-length":
            _vm.$store.getters["licenseDevices/totalItems"],
          "item-key": "key",
          loading: _vm.$store.getters["licenseDevices/loading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["licenseDevices/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push({
              path: _vm.license.isPod
                ? "/rooms/" +
                  item.podId +
                  "?preserveQuery=true&prev=license&id=" +
                  _vm.license.id
                : "/users/" +
                  item.id +
                  "?preserveQuery=true&prev=license&id=" +
                  _vm.license.id,
              query: { room: item.name }
            })
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.license.isPod
                      ? _vm.$t("common.rooms")
                      : _vm.$t("common.users"),
                    search: true
                  },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.creationDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("date2string")(item.creationDate)) + " "
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { outlined: "", small: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push(
                          _vm.license.isPod
                            ? "/rooms/" + item.id + "?preserveQuery=true"
                            : "/users/" + item.id + "?preserveQuery=true"
                        )
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.view")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }