var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "", "z-index": "11" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c("va-icon", {
                    staticClass: "cursor-pointer mt-n1",
                    attrs: { icon: "vi vi-chevron-left" },
                    on: {
                      click: function($event) {
                        _vm.target
                          ? _vm.$router.push(_vm.target + "?preserveQuery=true")
                          : _vm.$router.go(-1)
                      }
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c("span", [
        _vm._v(_vm._s(_vm.tooltip ? _vm.tooltip : _vm.$t("common.back")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }