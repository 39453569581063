import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Group, GroupDeserializer } from "@/models/group";
import GroupService from "@/services/groupService";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import * as listable from "./listableModule";
import router from "@/router";
import cloneDeep from "lodash/cloneDeep";

export interface GroupState extends listable.ListableState {
  groups: Group[];
  availableGroups: Group[];
  currentGroup: Group | undefined;
}

type GroupContext = ActionContext<GroupState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    groups: [],
    availableGroups: [],
    currentGroup: undefined,
  },
};

export const state = (): GroupState => cloneDeep(initialState);

export const getters: GetterTree<GroupState, RootState> = {
  ...listable.getters,
  ...{
    groups: (state): Group[] => {
      return state.groups;
    },
    availableGroups: (state): Group[] => {
      return state.availableGroups;
    },
    currentGroup: (state): Group | undefined => {
      return state.currentGroup;
    },
  },
};

export const mutations: MutationTree<GroupState> = {
  ...listable.mutations,
  ...{
    setGroups(state: GroupState, groups: Group[]) {
      state.groups = groups;
    },
    setAvailableGroups(state: GroupState, groups: Group[]) {
      state.availableGroups = groups;
    },
    setCurrentGroup(state: GroupState, group: Group) {
      state.currentGroup = group;
    },
  },
};

export const actions: ActionTree<GroupState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: GroupContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.groups = s.groups;
      context.state.availableGroups = s.availableGroups;
      context.state.currentGroup = s.currentGroup;
      context.dispatch("resetListable");
    },
    async fetchList(context: GroupContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await GroupService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit("setGroups", res.items.map(GroupDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchAll(context: GroupContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await GroupService.get();
        context.commit("setAvailableGroups", res.map(GroupDeserializer));
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async fetchGroup(context: GroupContext, id: string): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await GroupService.getDetails(id);
        context.commit("setCurrentGroup", res);
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
        router.push(`/rooms/groups`);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async addGroup(
      context: GroupContext,
      group: Partial<Group>
    ): Promise<string> {
      context.commit("setLoading", true);
      let idGroup = "";
      try {
        if (group.name && group.description) {
          idGroup = await GroupService.post(group.name, group.description);
        }
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
      return idGroup;
    },

    async deleteCurrentGroup(context: GroupContext): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("groupsList.deleteDialogTitle"),
          description: `${i18n.t("groupsList.deleteDescription")} "${
            context.state.currentGroup?.name
          }"`,
          callback: async () => {
            context.commit("setLoading", true);
            const group = context.state.currentGroup;
            if (group) {
              try {
                await GroupService.delete(group.id as string);
                router.push(`/rooms/groups`);
              } catch (e) {
                logger.error(e);
              } finally {
                context.commit("setLoading", false);
              }
            }
          },
        },
        { root: true }
      );
    },

    async updateCurrentGroup(context: GroupContext): Promise<void> {
      context.commit("setLoading", true);
      const group = context.state.currentGroup;
      if (group && group.id) {
        try {
          if (
            !group.address.formattedAddress ||
            group.address.formattedAddress == ""
          ) {
            group.address.coordinates.lat = undefined;
            group.address.coordinates.lng = undefined;
          }
          await GroupService.put(group);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("groupsList.updated"),
              type: "success",
            },
            { root: true }
          );
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
        }
      }
    },
  },
};
