import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import LicenseService from "@/services/licenseService";
import {
  LicenseDetailRoomDeserializer,
  LicenseDetailUserDeserializer,
  LicenseDetailUser,
  LicenseDetailRoom,
} from "@/models/license";
import * as listable from "./listableModule";
import cloneDeep from "lodash/cloneDeep";

export interface LicenseDevicesState extends listable.ListableState {
  licenseId: string;
  isPod: boolean;
  devices: LicenseDetailUser[] | LicenseDetailRoom[];
}

type LicenseDevicesContext = ActionContext<LicenseDevicesState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    licenseId: "",
    isPod: false,
    devices: [],
  },
};

export const state = (): LicenseDevicesState => cloneDeep(initialState);

export const getters: GetterTree<LicenseDevicesState, RootState> = {
  ...listable.getters,
  ...{
    devices: (state): LicenseDetailUser[] | LicenseDetailRoom[] => {
      return state.devices;
    },
  },
};

export const mutations: MutationTree<LicenseDevicesState> = {
  ...listable.mutations,
  ...{
    setLicenseId(state: LicenseDevicesState, id: string) {
      state.licenseId = id;
    },
    setIsPod(state: LicenseDevicesState, isPod: boolean) {
      state.isPod = isPod;
    },
  },
};

export const actions: ActionTree<LicenseDevicesState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: LicenseDevicesContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.licenseId = s.licenseId;
      context.state.isPod = s.isPod;
      context.state.devices = s.devices;
      context.dispatch("resetListable");
    },
    async fetchList(context: LicenseDevicesContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        if (context.state.isPod) {
          const details = await LicenseService.detailRoomSearch(
            context.state.licenseId,
            context.state.queryOptions,
            context.state.searchString
          );
          context.commit("setTotalItems", details.count);
          context.state.devices = details.items.map(
            LicenseDetailRoomDeserializer
          );
        } else {
          const details = await LicenseService.detailUserSearch(
            context.state.licenseId,
            context.state.queryOptions,
            context.state.searchString
          );
          context.commit("setTotalItems", details.count);
          context.state.devices = details.items.map(
            LicenseDetailUserDeserializer
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
  },
};
