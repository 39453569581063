























































































































































import Vue from "vue";
import IntegrationAtmoService from "@/services/integrationAtmoService";
import { RoomDetail } from "@/models/room";
import {
  AtmoDevicesResponseItem,
  MeasurementListElement,
  measurementsList,
} from "@/models/atmoDevice";
import VaBadge from "@/components/atoms/VaBadge.vue";
import AtmoChart from "@/components/pages/integrations/AtmoChart.vue";
import AtmoUtils, { ChartData } from "@/utils/atmoUtils";
import integrationAtmoService from "@/services/integrationAtmoService";
import dataTableMixin from "@/components/mixins/dataTableMixin";

export default Vue.extend({
  name: "RoomDetailSensors",
  components: { AtmoChart, VaBadge },
  data() {
    return {
      loadingInit: false,
      devices: [] as AtmoDevicesResponseItem[],
      poller: 0,
      waiting: false,
      atmoDataSetsLoading: [] as string[],
      atmoDataSets: {} as { [key: string]: ChartData },
    };
  },
  props: {
    roomId: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.attachPoller();
  },
  beforeDestroy() {
    this.detachPoller();
  },
  async mounted() {
    if (this.roomId) {
      this.loadingInit = true;
      await IntegrationAtmoService.getPodAtmoDashboard(this.roomId)
        .then(async (resp) => {
          this.devices = resp.devices;
          resp.devices.forEach((device) => {
            this.atmoDataSetsLoading.push(device.id);
            this.getAtmoDataSets(device)
              .then((resp) => {
                this.atmoDataSets[device.id] = resp;
              })
              .finally(() => {
                this.atmoDataSetsLoading = this.atmoDataSetsLoading.filter(
                  (item) => item !== device.id
                );
              });
          });
        })
        .finally(() => {
          this.loadingInit = false;
        });
    }
  },
  methods: {
    attachPoller() {
      this.poller = setInterval(async () => {
        if (!this.waiting) {
          await this.fetchData();
        }
      }, 60000);
    },
    detachPoller() {
      clearInterval(this.poller);
    },
    async fetchData(): Promise<void> {
      if (this.roomId) {
        this.waiting = true;
        await IntegrationAtmoService.getPodAtmoDashboard(this.roomId)
          .then(async (resp) => {
            this.devices = resp.devices;
            resp.devices.forEach((device) => {
              this.getAtmoDataSets(device).then((resp) => {
                this.atmoDataSets[device.id] = resp;
              });
            });
          })
          .finally(() => {
            this.waiting = false;
          });
      }
    },
    calcScore(measurement: MeasurementListElement, value: number) {
      if (measurement.scores) {
        if (measurement.scores.invert) {
          switch (true) {
            case value < measurement.scores.step1:
              return "vi-atmo-score-4-fill green--text";
            case value >= measurement.scores.step1 &&
              value < measurement.scores.step2:
              return "vi-atmo-score-3-fill light-green--text";
            case value >= measurement.scores.step2 &&
              value < measurement.scores.step3:
              return "vi-atmo-score-2-fill orange--text";
            case value >= measurement.scores.step3:
              return "vi-atmo-score-1-fill red--text";
            default:
              return "";
          }
        } else {
          switch (true) {
            case value < measurement.scores.step1:
              return "vi-atmo-score-1-fill red--text";
            case value >= measurement.scores.step1 &&
              value < measurement.scores.step2:
              return "vi-atmo-score-2-fill orange--text";
            case value >= measurement.scores.step2 &&
              value < measurement.scores.step3:
              return "vi-atmo-score-3-fill light-green--text";
            case value >= measurement.scores.step3:
              return "vi-atmo-score-4-fill green--text";
            default:
              return "";
          }
        }
      } else {
        return "";
      }
    },
    async getAtmoDataSets(device: AtmoDevicesResponseItem): Promise<ChartData> {
      const measurements = await integrationAtmoService.getMeasurements(
        device.id
      );
      return AtmoUtils.buildDatasets(measurements);
    },
  },
  computed: {
    dataTableMixin() {
      return dataTableMixin;
    },
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    measurementsList(): MeasurementListElement[] {
      return measurementsList;
    },
  },
});
