<script>
import { Line } from "vue-chartjs";
import Vue from "vue";

export default Vue.extend({
  extends: Line,
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: this.$vuetify.theme.currentTheme.chartText,
          },
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                beginAtZero: true,
                max: 100,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5,
          },
        },
        tension: 1,
        stepped: 0,
        borderDash: [],
        spanGaps: true,
        tooltips: {
          enabled: false,
          mode: "point",
          intersect: false,
          position: "average",
        },
      },
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
  watch: {
    data: function () {
      this.$data._chart.destroy();
      this.renderChart(this.data, this.options);
      //this.update();
    },
  },
});
</script>

<style lang="scss" scoped></style>
