var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [_c("va-icon", { attrs: { icon: "vi vi-atmo-co2", size: "l" } })],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "p",
              { staticClass: "text-h3 font-weight-bold d-inline-block mb-0" },
              [_vm._v("CO2")]
            )
          ]),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c("div", { staticClass: "align-end text-right" }, [
              _c(
                "p",
                { staticClass: "text-h5 grey--text font-weight-bold mb-0" },
                [_vm._v("ppm")]
              )
            ])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-8 mb-8" }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-justify" }, [
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Normal: 400-1,000 ppm")
      ]),
      _c("p", [
        _vm._v(
          " Normal indoor CO2 concentrations hover around 400-1,000 ppm. This means that the space is properly ventilated and has consistent air exchange. "
        )
      ]),
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Mild symptoms: 1,000-2,000 ppm")
      ]),
      _c("p", [
        _vm._v(
          " Above 1,000 ppm, CO2 begins to cause noticeable symptoms as oxygen in the air is displaced by CO2 molecules..Common but mild symptoms often resulting from CO2 in this range include, drowsiness, feeling of stuffiness, mild confusion, disorientation. "
        )
      ]),
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Moderate symptoms: 2,000-5,000 ppm")
      ]),
      _c("p", [
        _vm._v(
          " Beyond 2,000 ppm, CO2 can cause disruptive health and cognitive symptoms, including , headaches, feeling sleepy, tightness in chest, increase in heart rate, reduced attention, lack of concentration, nausea. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }