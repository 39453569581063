































































































































































































































































































































































import Vue, { VueConstructor } from "vue";
import FileUploader from "@/components/shared/FileUploader.vue";
import { FileFormatted } from "@/models/file";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "HomeLauncherConfiguration",
  components: { VaTextField, VaSwitch, FileUploader },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
      file: null,
      startInstantModeBoolean: false,
      idleTimeOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 20,
          text: "20",
        },
        {
          value: 30,
          text: "30",
        },
        {
          value: 60,
          text: "60",
        },
        {
          value: 90,
          text: "90",
        },
        {
          value: 120,
          text: "120",
        },
        {
          value: 180,
          text: "180",
        },
        {
          value: 300,
          text: "300",
        },
      ],
      languagesOptions: [
        //TODO: MOVE ME TO DEDICATED SERVICE?
        {
          value: "en-US",
          text: this.$t("common.english") as string,
          icon: require("@/assets/images/flags/en.png"),
        },
        {
          value: "it-IT",
          text: this.$t("common.italian") as string,
          icon: require("@/assets/images/flags/it.png"),
        },
        {
          value: "fr-FR",
          text: this.$t("common.french") as string,
          icon: require("@/assets/images/flags/fr.png"),
        },
        {
          value: "es-ES",
          text: this.$t("common.spanish") as string,
          icon: require("@/assets/images/flags/es.png"),
        },
        // {
        //   value: "ru-RU",
        //   text: this.$t("common.russian") as string,
        //   icon: require("@/assets/images/flags/ru.png"),
        // },
      ],
      digitalSignageQrCodeModeOptions: [
        {
          value: 0,
          text: "None",
        },
        {
          value: 1,
          text: "Qr Code",
        },
        {
          value: 2,
          text: "Text",
        },
      ],
      digitalSignageQrCodePositionOptions: [
        {
          value: 0,
          text: "BottomLeft",
        },
        {
          value: 1,
          text: "BottomRight",
        },
        {
          value: 2,
          text: "TopLeft",
        },
        {
          value: 3,
          text: "TopRight",
        },
      ],
      DigitalSignageQrCodeMargin: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    };
  },
  mounted() {
    this.startInstantModeBoolean = this.fields.StartInstantMode == 1;
    // if (!this.fields.MaxIdleTime) this.fields.MaxIdleTime = 60
  },
  computed: {
    startInstantMode() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.fields.StartInstantMode == 1;
    },
  },
  watch: {
    startInstantMode: {
      handler: async function (val) {
        this.startInstantModeBoolean = val;
      },
      deep: true,
    },
    fields(val) {
      this.DigitalSignageQrCodeMargin = {
        left: val.DigitalSignageQrCodeMargin?.split(";").map(Number)[0],
        top: val.DigitalSignageQrCodeMargin?.split(";").map(Number)[1],
        right: val.DigitalSignageQrCodeMargin?.split(";").map(Number)[2],
        bottom: val.DigitalSignageQrCodeMargin?.split(";").map(Number)[3],
      };
    },
  },
  methods: {
    onChangeInstantMode(val: boolean) {
      this.fields.StartInstantMode = val ? 1 : 0;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("int");
    },
    uploadLogo(logo: FileFormatted) {
      this.fields.Logo = logo;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("file");
    },
    uploadBackgrounds(backgrounds: Array<FileFormatted>) {
      this.fields.Backgrounds = backgrounds;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("fileArray");
    },
    onChangeMargins() {
      this.fields.DigitalSignageQrCodeMargin = `${this.DigitalSignageQrCodeMargin.left};${this.DigitalSignageQrCodeMargin.top};${this.DigitalSignageQrCodeMargin.right};${this.DigitalSignageQrCodeMargin.bottom};`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("string");
    },
  },
});
