import { Tag, TagDeserializer } from "@/models/tag";
import i18n from "@/i18n";
import { GroupedPolicy, PolicyGroupDeserializer } from "@/models/policy";
import { Group, GroupDeserializer } from "@/models/group";
import { VideoConferenceAuthenticationRequest } from "@/models/videoConference";
import { CalendarProvider } from "@/models/calendar";
import { FileFormatted } from "@/models/file";
import {
  FilterBy,
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";
import {
  MaintenanceLog,
  MaintenanceLogDeserializer,
} from "@/models/maintenance";

//TODO: remove after backend implementation
//import { getRandomLocation } from "@/utils";

export interface RoomPerformanceStat {
  usage: number | null;
  alert: boolean;
}

export interface RoomPerformance {
  cpu: RoomPerformanceStat;
  memory: RoomPerformanceStat;
  disk: RoomPerformanceStat;
  gpu: RoomPerformanceStat;
}

export interface RoomVideoConferenceSystem {
  accountEnable: boolean;
  provider: number;
  userName: string;
}

// export interface RoomCoordinates {
//   lat: number;
//   lng: number;
// }

/***** OVERALL STATE *****/

export enum RoomOverallState {
  GOOD,
  AVERAGE,
  POOR,
  OFFLINE,
}

export interface RoomOverallStateData {
  id: RoomOverallState;
  name: string;
  label: string;
  color: string;
}

export const RoomOverallStatesData: Array<RoomOverallStateData> = [
  {
    id: RoomOverallState.GOOD,
    name: "ok",
    label: i18n.t("common.ok"),
    color: "success",
  } as RoomOverallStateData,
  {
    id: RoomOverallState.AVERAGE,
    name: "average",
    label: i18n.t("common.warning"),
    color: "warning",
  } as RoomOverallStateData,
  {
    id: RoomOverallState.POOR,
    name: "poor",
    label: i18n.t("common.critical"),
    color: "error",
  } as RoomOverallStateData,
  {
    id: RoomOverallState.OFFLINE,
    name: "offline",
    label: i18n.t("common.offline"),
    color: "black",
  } as RoomOverallStateData,
] as Array<RoomOverallStateData>;

export interface CalendarParameter {
  calendaryType: 1 | 2 | 3;
  calendarToken: Record<string, any> | null;
  calendarId?: string;
  calendarName?: string;
  enabled: boolean;
}

export interface RoomCalendar {
  provider: CalendarProvider;
  name: string;
  calendarId: string;
}

export interface RoomCommandScript {
  fileId: string;
  fileName: string;
  runAsSystem: boolean;
}

export interface RoomCapacity {
  peopleCount: boolean;
  capacity: number;
  showNotification: boolean;
  peopleCountValue?: number | undefined;
}

export enum RoomCommandTypes {
  MACHINE_NAME = 1,
  VOLUME,
  VOLUME_LEVEL,
  MICROPHONE,
  MICROPHONE_LEVEL,
  LAUNCH,
  STOP,
  CALENDAR = 11,
  LOG = 15,
  RESTART_MACHINE,
  RESTART_AIRSERVER,
  SCREENSHOT,
  CONFERENCE_SYSTEM,
  SCRIPT,
  ROOM_CAPACITY,
}

export type RoomCommandParameterTypes =
  | boolean
  | string
  | number
  | CalendarParameter
  | null
  | VideoConferenceAuthenticationRequest
  | RoomCommandScript
  | RoomCapacity;

export enum RoomCommandStatus {
  NONE,
  PROCESSING,
  EXPIRED = 3,
  CANCELLED = 4,
  FAILED = 5,
  COMPLETED = 6,
}

export const ROOM_COMMAND_STATUS_DATA: Array<{ label: string; icon: string }> =
  [
    {
      label: i18n.t("common.none") as string,
      icon: "",
    },
    {
      label: "",
      icon: "",
    },
    {
      label: i18n.t("common.processing") as string,
      icon: "",
    },
    {
      label: i18n.t("common.expired") as string,
      icon: "",
    },
    {
      label: i18n.t("common.cancelled") as string,
      icon: "",
    },
    {
      label: i18n.t("common.failed") as string,
      icon: "",
    },
    {
      label: i18n.t("common.completed") as string,
      icon: "",
    },
  ];

export interface RoomCommandField {
  type: string;
  label: string;
  default: string | number | boolean | null;
  value?: string | number | boolean | FileFormatted | RoomCommandScript | null;
}

export interface RoomCommand {
  id: RoomCommandTypes;
  name: string;
  label: string;
  field?: RoomCommandField;
}

export const ROOM_COMMANDS = [
  {
    id: 1,
    name: "MACHINE_NAME",
    label: i18n.t("commands.MACHINE_NAME"),
    field: {
      type: "text",
      label: i18n.t("common.name"),
      default: null,
    } as RoomCommandField,
  } as RoomCommand,
  {
    id: 2,
    name: "VOLUME",
    label: i18n.t("commands.VOLUME"),
    field: {
      type: "radio",
      default: null,
    } as RoomCommandField,
  } as RoomCommand,
  {
    id: 3,
    name: "VOLUME_LEVEL",
    label: i18n.t("commands.VOLUME_LEVEL"),
    field: {
      type: "number",
      label: i18n.t("common.volume"),
      default: 50,
    } as RoomCommandField,
  } as RoomCommand,
  {
    id: 4,
    name: "MICROPHONE",
    label: i18n.t("commands.MICROPHONE"),
    field: {
      type: "radio",
      default: null,
    } as RoomCommandField,
  } as RoomCommand,
  {
    id: 5,
    name: "MICROPHONE_LEVEL",
    label: i18n.t("commands.MICROPHONE_LEVEL"),
    field: {
      type: "number",
      label: i18n.t("common.volume"),
      default: 50,
    } as RoomCommandField,
  } as RoomCommand,
  {
    id: 6,
    name: "LAUNCH",
    label: i18n.t("commands.LAUNCH"),
  } as RoomCommand,
  {
    id: 7,
    name: "STOP",
    label: i18n.t("commands.STOP"),
  } as RoomCommand,
  {
    id: 9,
    name: "UPDATE",
    label: i18n.t("commands.UPDATE"),
  } as RoomCommand,
  {
    id: 16,
    name: "RESTART_MACHINE",
    label: i18n.t("commands.RESTART_MACHINE"),
  } as RoomCommand,
  {
    id: 17,
    name: "RESTART_AIRSERVER",
    label: i18n.t("commands.RESTART_AIRSERVER"),
  } as RoomCommand,
  {
    id: 20,
    name: "SCRIPT",
    label: i18n.t("commands.SCRIPT"),
    field: {
      type: "file",
      label: i18n.t("common.script"),
      default: null,
    } as RoomCommandField,
  } as RoomCommand,
];

export interface RoomCommandData {
  commandTypeId: RoomCommandTypes;
  commandParameter?: RoomCommandParameterTypes;
}

export interface RoomLastCommand {
  commandId: string;
  commandName: string;
  commandParameter: string | boolean | number;
  isScheduled: boolean;
  startDate: number;
  status: number;
}

export enum RoomDisplayType {
  MAIN,
  EXTENDED,
  CONSOLE,
}

export interface RoomDisplay {
  index: number;
  isPrimary: boolean;
  deviceName: string;
  height: number;
  width: number;
  virtualHeight: number;
  virtualWidth: number;
  model: string;
  refreshRate: number;
  screenType: RoomDisplayType;
  top: number;
  left: number;
  preview?: string;
}

export interface RoomIssue {
  issueTypeCode: string;
  issueStartDate: number;
}

export interface RoomDetail {
  id: string;
  name: string;
  version: string;
  windowVersion: string;
  upTime: number;
  description: string;
  camera: boolean;
  isAirServerDisabled: boolean;
  airServer: boolean;
  status: boolean;
  isEssential: boolean;
  isMaintenanceModeEnabled: boolean;
  isMaintenanceExpired: boolean;
  hasOngoingMeeting: boolean;
  microphone?: boolean;
  microphoneLevel?: number;
  volume: boolean;
  volumeLevel: number;
  lastUpdate: number;
  tags: Array<Tag>;
  preview?: string;
  online: boolean;
  issues: Array<RoomIssue>;
  lastVersion: string;
  policies: Array<GroupedPolicy>;
  group: Group;
  lastCommands: Array<RoomLastCommand>;
  hasUpdate: boolean;
  calendar: RoomCalendar | null;
  workerVersion: string;
  displays: Array<RoomDisplay>;
  overallState: number;
  videoConferenceSystems: Array<RoomVideoConferenceSystem>;
  betaEnabled: boolean;
  isCustomBuildEnabled: boolean;
  performance: RoomPerformance | null;
  people: number | null;
  roomCapacity: RoomCapacity | undefined;
  buildingId: string | undefined;
  podReleaseType: string;
  licenseKey: string;
  maintenanceMode?: MaintenanceLog;
  hasAtmoDevices?: boolean;
  // position: RoomCoordinates | undefined;
}

export default {
  deserializeNON(
    //TODO candidate to removale
    input: Record<
      string,
      | string
      | number
      | boolean
      | Array<Tag>
      | Array<GroupedPolicy>
      | Group
      | Array<RoomLastCommand>
      | any
    >
  ): RoomDetail {
    const roomCapacity = {
      peopleCount: input.roomCapacity?.peopleCount || false,
      capacity: input.roomCapacity?.capacity ? input.roomCapacity?.capacity : 0,
      showNotification: input.roomCapacity?.showNotification,
      peopleCountValue: input.roomCapacity?.peopleCountValue
        ? input.roomCapacity.peopleCountValue
        : undefined,
    };
    //TODO: remove me after backend implementation
    //const roomCoords = getRandomLocation(45.464664, 9.18854, 10000);
    return {
      id: input.podId,
      name: input.machineName
        ? input.machineName
        : input.roomName
        ? input.roomName
        : "",
      version: input.podVersion,
      windowVersion: input.windowVersion,
      upTime: input.uptime,
      description: input.machineDescription || input.machineName,
      camera: input.isCameraEnabled,
      isAirServerDisabled: input.isAirServerDisabled,
      airServer: input.isAirServerRunning,
      status: input.isPodRunning,
      microphone:
        input.microphoneIsMuted === undefined ||
        input.microphoneIsMuted === null
          ? undefined
          : !input.microphoneIsMuted,
      microphoneLevel: input.microphoneVolume,
      volume: !input.isMuted,
      volumeLevel: input.volume ? input.volume : 0,
      lastUpdate: input.lastUpdateDate || Date.now(),
      tags: (input.tags as Array<Tag>) || [],
      online: input.isOnline,
      issues: input.issues ? input.issues : ([] as Array<RoomIssue>),
      lastVersion: input.latestPodVersion,
      policies: input.policies as Array<GroupedPolicy>,
      group: input.group as Group,
      lastCommands: input.lastCommands as Array<RoomLastCommand>,
      hasUpdate: input.hasUpdate,
      calendar: input.calendar,
      workerVersion: input.workerVersion,
      displays: input.displays as Array<RoomDisplay>,
      overallState: input.overallState,
      videoConferenceSystems:
        input.videoConferenceSystems as Array<RoomVideoConferenceSystem>,
      betaEnabled: input.isBetaEnabled,
      isCustomBuildEnabled: input.isCustomBuildEnabled,
      performance: input.performance || null,
      people: input.peopleCount === undefined ? null : input.peopleCount,
      roomCapacity: roomCapacity,
      buildingId: input.buildingId || undefined,
      podReleaseType: input.podReleaseType || "",
      hasAtmoDevices: input.hasAtmoDevices || false,
      // position: roomCoords,
    } as RoomDetail;
  },
  serialize(): Record<string, string | number | undefined> {
    return {};
  },
  // serialize(input: RoomDetail): Record<string, string | number | undefined> {
  //   return {};
  // },
};

// ---------------- NEW MODELS ----------------

export const roomConfig = {
  roomName: {
    dtoName: "roomName",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  overallState: {
    dtoName: "overallState",
    sortable: false,
    filterable: false,
    label: i18n.t("common.overallState"),
  },
  isMaintenanceModeEnabled: {
    dtoName: "isMaintenanceModeEnabled",
    sortable: false,
    filterable: false,
    label: i18n.t("common.maintenance"),
  },
  hasOngoingMeeting: {
    dtoName: "hasOngoingMeeting",
    sortable: false,
    filterable: false,
    label: i18n.t("common.meetingsInProgress"),
  },
  currentIssueCount: {
    dtoName: "currentIssueCount",
    sortable: false,
    filterable: false,
    label: i18n.t("common.issues"),
  },
  group: {
    dtoName: "group",
    sortable: false,
    filterable: false,
    label: i18n.t("common.group"),
  },
  tags: {
    dtoName: "tags",
    sortable: false,
    filterable: false,
    label: i18n.t("common.tags"),
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function RoomDeserializer(input: any): RoomDetail {
  const roomCapacity = {
    peopleCount: input.roomCapacity?.peopleCount || false,
    capacity: input.roomCapacity?.capacity ? input.roomCapacity?.capacity : 0,
    showNotification: input.roomCapacity?.showNotification,
    peopleCountValue: input.roomCapacity?.peopleCountValue
      ? input.roomCapacity.peopleCountValue
      : undefined,
  };
  //TODO: remove me after backend implementation
  //const roomCoords = getRandomLocation(45.464664, 9.18854, 10000);

  const maintenanceMode = input.maintenanceMode
    ? MaintenanceLogDeserializer(input.maintenanceMode)
    : undefined;
  return {
    id: input.podId,
    name: input.machineName
      ? input.machineName
      : input.roomName
      ? input.roomName
      : "",
    version: input.podVersion,
    windowVersion: input.windowVersion,
    upTime: input.uptime,
    description: input.machineDescription || input.machineName,
    camera: input.isCameraEnabled,
    isAirServerDisabled: input.isAirServerDisabled,
    airServer: input.isAirServerRunning,
    status: input.isPodRunning,
    currentIssueCount: input.currentIssueCount,
    isEssential: input.isEssential || false,
    isMaintenanceModeEnabled: input.isMaintenanceModeEnabled || false,
    isMaintenanceExpired: input.isMaintenanceExpired || false,
    hasOngoingMeeting: input.hasOngoingMeeting || false,
    microphone:
      input.microphoneIsMuted === undefined || input.microphoneIsMuted === null
        ? undefined
        : !input.microphoneIsMuted,
    microphoneLevel: input.microphoneVolume,
    volume: !input.isMuted,
    volumeLevel: input.volume ? input.volume : 0,
    lastUpdate: input.lastUpdateDate || Date.now(),
    tags: input.tags ? input.tags.map(TagDeserializer) : [],
    online: input.isOnline,
    issues: input.issues ? input.issues : ([] as Array<RoomIssue>),
    lastVersion: input.latestPodVersion,
    // policies: input.policies as Array<GroupedPolicy>,
    policies: input.policies ? PolicyGroupDeserializer(input.policies) : [],
    group: GroupDeserializer(input.group),
    lastCommands: input.lastCommands as Array<RoomLastCommand>,
    hasUpdate: input.hasUpdate,
    calendar: input.calendar,
    workerVersion: input.workerVersion,
    displays: input.displays as Array<RoomDisplay>,
    overallState: input.overallState,
    videoConferenceSystems:
      input.videoConferenceSystems as Array<RoomVideoConferenceSystem>,
    betaEnabled: input.isBetaEnabled || false,
    isCustomBuildEnabled: input.isCustomBuildEnabled || false,
    performance: input.performance || null,
    people: input.peopleCount === undefined ? null : input.peopleCount,
    roomCapacity: roomCapacity,
    buildingId: input.buildingId || undefined,
    podReleaseType: input.podReleaseType || "",
    licenseKey: input.licenseKey || "",
    maintenanceMode: maintenanceMode,
    hasAtmoDevices: input.hasAtmoDevices || false,
    // position: roomCoords,
  } as RoomDetail;
}

export function RoomSearchSerializer(
  options: OptionsTable,
  searchString?: string,
  additionalFilters?: FilterBy[]
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, roomConfig),
    filterBy: {
      values: FilterBySerializer(roomConfig, searchString, additionalFilters),
    },
  };
}
