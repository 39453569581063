import { render, staticRenderFns } from "./AtmoInfoPressure.vue?vue&type=template&id=31e6c543&scoped=true&"
var script = {}
import style0 from "./AtmoInfoPressure.vue?vue&type=style&index=0&id=31e6c543&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e6c543",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31e6c543')) {
      api.createRecord('31e6c543', component.options)
    } else {
      api.reload('31e6c543', component.options)
    }
    module.hot.accept("./AtmoInfoPressure.vue?vue&type=template&id=31e6c543&scoped=true&", function () {
      api.rerender('31e6c543', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/integrations/templates/AtmoInfoPressure.vue"
export default component.exports