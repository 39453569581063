var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [_c("va-icon", { attrs: { icon: "vi vi-atmo-noise", size: "l" } })],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "p",
              { staticClass: "text-h3 font-weight-bold d-inline-block mb-0" },
              [_vm._v("Noise")]
            )
          ]),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c("div", { staticClass: "align-end text-right" }, [
              _c(
                "p",
                { staticClass: "text-h5 grey--text font-weight-bold mb-0" },
                [_vm._v("dB")]
              )
            ])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-8 mb-8" }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-justify" }, [
      _c("p", [
        _vm._v(
          " The background noise level should be low enough not to interfere with verbal communication. "
        )
      ]),
      _c("p", [
        _vm._v(
          " A background noise level below 35 dB(A) is ideal for a meeting room. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }