var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "va-title" }, [_vm._v("Integrations")]),
      _c(
        "router-link",
        { attrs: { to: "/integrations/atmo" } },
        [
          _c(
            "v-card",
            { attrs: { link: "", "max-width": "344" } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/integrations/logo-atmo.jpg"),
                  alt: "ATMO"
                }
              }),
              _c("v-card-title", { staticClass: "text-h5 font-weight-bold" }, [
                _vm._v(" ATMO ")
              ]),
              _c("v-card-subtitle", [
                _vm._v(" Atmocube / Atmotube air quality sensors ")
              ]),
              _c(
                "v-card-actions",
                [_c("VaButton", { attrs: { cta: "Configure" } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }