import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import {
  AtmoDevicesResponse,
  AtmoDevicesResponseItem,
  AtmoDevicesResponseItemMeasurement,
  AtmoParameter,
} from "@/models/atmoDevice";

export default {
  async getParameters(): Promise<any> {
    const res: AxiosResponse = await axios.get(
      apiService.integrations.atmo.integrationParameters()
    );
    return res.data.parameters;
  },
  async getPodAtmoDashboard(podId: string): Promise<AtmoDevicesResponse> {
    const data = {
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      offset: "+00",
    };
    const res: AxiosResponse = await axios.post(
      apiService.integrations.atmo.podAtmoDashboard().replace(":id", podId),
      data
    );
    return res.data;
  },
  async getPodAtmoDashboardPublic(
    publicId: string
  ): Promise<AtmoDevicesResponseItem> {
    const data = {
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      offset: "+00",
    };
    const res: AxiosResponse = await axios.post(
      apiService.integrations.atmo
        .podAtmoDashboardPublic()
        .replace(":id", publicId),
      data
    );
    return res.data;
  },
  async getMeasurements(
    deviceId: string,
    startDate = new Date().toISOString().split("T")[0],
    endDate = new Date().toISOString().split("T")[0],
    offset = "+00"
  ): Promise<AtmoDevicesResponseItemMeasurement[]> {
    const data = {
      startDate,
      endDate,
      offset,
    };
    const res: AxiosResponse = await axios.post(
      apiService.integrations.atmo
        .deviceMeasurements()
        .replace(":id", deviceId),
      data
    );
    return res.data;
  },
  async getLatestMeasurementsPublic(
    devicePublicId: string
  ): Promise<AtmoDevicesResponseItemMeasurement[]> {
    const res: AxiosResponse = await axios.get(
      apiService.integrations.atmo
        .deviceLatestMeasurementsPublic()
        .replace(":id", devicePublicId)
    );
    return res.data;
  },
  async updateParameters(parameters: { APIKey: string }): Promise<any> {
    const data = {
      parameters: [] as AtmoParameter[],
    };
    for (const [key, value] of Object.entries(parameters)) {
      data.parameters.push({
        parameterName: key,
        parameterValue: value,
      });
    }
    const res: AxiosResponse = await axios.put(
      apiService.integrations.atmo.integrationParameters(),
      data
    );
    return res.data;
  },
  async getDevices(): Promise<[]> {
    const res: AxiosResponse = await axios.get(
      apiService.integrations.atmo.devices()
    );
    return res.data;
  },
  async updateDevicePodRelation(deviceId: string, podId: string): Promise<any> {
    const data = {
      podId: podId,
    };
    const res: AxiosResponse = await axios.put(
      apiService.integrations.atmo.deviceRelations().replace(":id", deviceId),
      data
    );
    return res.data;
  },
  async deleteDevicePodRelation(deviceId: string): Promise<any> {
    const res: AxiosResponse = await axios.delete(
      apiService.integrations.atmo.deviceRelations().replace(":id", deviceId)
    );
    return res.data;
  },
};
