var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "p",
              { staticClass: "text-h3 font-weight-bold d-inline-block mb-0" },
              [_vm._v("CH2O")]
            )
          ]),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c("div", { staticClass: "align-end text-right" }, [
              _c(
                "p",
                { staticClass: "text-h5 grey--text font-weight-bold mb-0" },
                [_vm._v("ppm")]
              )
            ])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-8 mb-8" }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-justify" }, [
      _c("p", [
        _vm._v(
          " Indoor air is more likely to contain dangerous levels of formaldehyde than outdoor air. Formaldehyde is measured in per million parts of air (PPM). Most symptoms set in when formaldehyde exceeds 0.1 PPM. The main sources of formaldehyde are: "
        )
      ]),
      _c("p", [_vm._v("• Burning wood, gas, kerosene, tobacco, natural gas")]),
      _c("p", [_vm._v("• Household chemicals, paints, and paper coating")]),
      _c("p", [
        _vm._v(
          " • Building materials, such as particleboard, plywood, strand board, and pressed-wood products "
        )
      ]),
      _c("p", [_vm._v("• Emissions from living organisms")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }