import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import podApplicationService from "@/services/podApplicationService";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import * as listable from "./listableModule";
import {
  PodApplication,
  PodApplicationDeserializer,
} from "@/models/podApplication";
import clone from "lodash/clone";
import { FileFormatted } from "@/models/file";
import cloneDeep from "lodash/cloneDeep";

export interface PodApplicationState extends listable.ListableState {
  currentPodApplication: PodApplication;
  podApplications: PodApplication[];
  podApplicationList: PodApplication[];
  editing: boolean;
}

const emptyPodApplication = {
  name: "",
  path: "",
  parameters: "",
  description: "",
  isDefault: false,
} as PodApplication;

type PodApplicationContext = ActionContext<PodApplicationState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    podApplications: [],
    currentPodApplication: clone(emptyPodApplication),
    podApplicationList: [],
    editing: false,
  },
};

export const state = (): PodApplicationState => cloneDeep(initialState);

export const getters: GetterTree<PodApplicationState, RootState> = {
  ...listable.getters,
  ...{
    currentPodApplication: (state): PodApplication | undefined => {
      return state.currentPodApplication;
    },
    podApplications: (state): PodApplication[] => {
      return state.podApplications;
    },
    podApplicationList: (state): PodApplication[] => {
      return state.podApplicationList;
    },
    editing: (state): boolean => {
      return state.editing;
    },
  },
};
export const mutations: MutationTree<PodApplicationState> = {
  ...listable.mutations,
  ...{
    setCurrentPodApplication(
      state: PodApplicationState,
      podApplication: PodApplication
    ) {
      state.currentPodApplication = podApplication;
    },
    setCurrentPodApplicationIcon(
      state: PodApplicationState,
      icon: FileFormatted
    ) {
      state.currentPodApplication.icon = icon;
    },
    resetCurrentPodApplication(state: PodApplicationState) {
      state.currentPodApplication = clone(emptyPodApplication);
    },
    setPodApplications(
      state: PodApplicationState,
      podApplications: PodApplication[]
    ) {
      state.podApplications = podApplications;
    },
    updatePodApplication(
      state: PodApplicationState,
      currentPodApplication: PodApplication
    ) {
      state.podApplications = state.podApplications.map((podApplication) => {
        if (podApplication.id === currentPodApplication.id) {
          return currentPodApplication;
        }
        return podApplication;
      });
    },
    deletePodApplication(
      state: PodApplicationState,
      currentPodApplication: PodApplication
    ) {
      state.podApplications = state.podApplications.filter((podApplication) => {
        return podApplication.id !== currentPodApplication.id;
      });
    },
    addPodApplication(
      state: PodApplicationState,
      currentPodApplication: PodApplication
    ) {
      state.podApplications.push(currentPodApplication);
    },
    setEditing(state: PodApplicationState, editing: boolean) {
      state.editing = editing;
    },
  },
};
export const actions: ActionTree<PodApplicationState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: PodApplicationContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.podApplications = s.podApplications;
      context.state.currentPodApplication = s.currentPodApplication;
      context.state.podApplicationList = s.podApplicationList;
      context.dispatch("resetListable");
    },
    async fetchList(context: PodApplicationContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await podApplicationService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit(
          "setPodApplications",
          res.items.map(PodApplicationDeserializer)
        );
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchAll(context: PodApplicationContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await podApplicationService.fetch();
        context.state.podApplicationList = res;
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async deleteCurrentPodApplication(
      context: PodApplicationContext
    ): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("podApplications.deleteDialogTitle"),
          description: `${i18n.t("podApplications.deleteDescription")} "${
            context.state.currentPodApplication.name
          }"`,
          callback: async () => {
            context.commit("setLoading", true);
            const podApplication = context.state.currentPodApplication;
            if (podApplication?.id) {
              try {
                await podApplicationService.delete(podApplication);
                context.commit("deletePodApplication", podApplication);
                context.commit(
                  "notifications/displayNotification",
                  {
                    message: i18n.t("podApplications.deleted"),
                    type: "success",
                  },
                  { root: true }
                );
              } catch (e) {
                logger.error(e);
              } finally {
                context.commit("setLoading", false);
                context.commit("setEditing", false);
              }
            }
          },
        },
        { root: true }
      );
    },

    async createPodApplication(context: PodApplicationContext): Promise<void> {
      context.commit("setLoading", true);
      const podApplication = context.state.currentPodApplication;
      try {
        const res = await podApplicationService.post(podApplication);
        podApplication.id = res.data;
        context.dispatch("fetchList");
        // context.commit("addPodApplication", podApplication);
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("podApplications.created"),
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
        context.commit("setEditing", false);
      }
    },

    async updateCurrentPodApplication(
      context: PodApplicationContext
    ): Promise<void> {
      context.commit("setLoading", true);
      const podApplication = context.state.currentPodApplication;
      try {
        await podApplicationService.put(podApplication);
        context.commit("updatePodApplication", podApplication);
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("podApplications.updated"),
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
        context.commit("setEditing", false);
      }
    },
  },
};
