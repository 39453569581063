var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        id: "main-menu",
        color: "navigation",
        permanent: !_vm.smallBreakpoint,
        temporary: _vm.smallBreakpoint,
        "mini-variant": !(
          !_vm.$store.getters["session/collapseMenu"] || _vm.smallBreakpoint
        ),
        "mini-variant-width": 80
      },
      model: {
        value: _vm.drawerOpen,
        callback: function($$v) {
          _vm.drawerOpen = $$v
        },
        expression: "drawerOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "pt-4 pb-6 pl-4 d-flex flex-column" },
        [
          _c("va-avatar", {
            attrs: { color: "secondary lighten-4", image: _vm.logo }
          }),
          !_vm.smallBreakpoint
            ? _c(
                "div",
                {
                  staticClass:
                    "\n        ml-auto\n        mb-n7\n        mt-n2\n        mr-n3\n        rounded-full\n        menu-collapse\n        border-a\n        cursor-pointer\n      ",
                  on: {
                    click: function($event) {
                      return _vm.$store.commit("session/toggleCollapseMenu")
                    }
                  }
                },
                [
                  _c("va-icon", {
                    attrs: {
                      color: "secondary lighten-2",
                      icon: _vm.$store.getters["session/collapseMenu"]
                        ? "mdi-chevron-right"
                        : "mdi-chevron-left"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary", ripple: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { to: "/dashboard", exact: "" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-speedometer-1" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Dashboard")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/rooms", exact: "" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-presentation-1" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Rooms List")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/rooms/groups" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-networking" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Groups")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/rooms/tags" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-tags-stack" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Tags")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/rooms/task-scheduler" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-todo" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Tasks scheduler")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/rooms/policies" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-settings" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Policies")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/users" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-multiple-19" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Users")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/rooms/applications" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-app-store" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Applications")])
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                { attrs: { to: "/licenses" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-license-key-1" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Licenses")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/integrations" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-integrations" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Integrations")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/audit-logs" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("va-icon", {
                        staticClass: "mx-auto",
                        attrs: { icon: "vi vi-survey" }
                      })
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Audit Logs")])
                ],
                1
              ),
              !_vm.downloadDisabled
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/download-software" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("va-icon", {
                            staticClass: "mx-auto",
                            attrs: { icon: "vi vi-backup" }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Download")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }