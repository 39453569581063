var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingInit
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "pa-4 text-center" },
            [
              _c(
                "v-card-text",
                [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : !_vm.devices.length
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "pa-4 text-center" },
            [_c("v-card-text", [_vm._v(" No associated sensors found.")])],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        _vm._l(_vm.devices, function(device) {
          return _c(
            "v-row",
            { key: device.id, attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "ma-2" },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "9" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { attrs: { cols: "2" } }, [
                                          _c("img", {
                                            staticClass:
                                              "rounded d-inline-block",
                                            staticStyle: {
                                              height: "auto",
                                              "max-width": "100%",
                                              width: "100px"
                                            },
                                            attrs: {
                                              src: require("@/assets/images/integrations/logo-atmo.jpg"),
                                              alt: "ATMO"
                                            }
                                          })
                                        ]),
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            { staticClass: "text-h5 mb-1" },
                                            [_vm._v(_vm._s(device.name))]
                                          ),
                                          _vm._v(
                                            " Atmocube device id: " +
                                              _vm._s(device.id) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "3" }
                                  },
                                  [
                                    device.status === "online"
                                      ? _c("va-badge", {
                                          attrs: {
                                            text: "Online",
                                            color: "green"
                                          }
                                        })
                                      : _vm._e(),
                                    device.status === "offline"
                                      ? _c("va-badge", {
                                          attrs: {
                                            text: "Offline",
                                            color: "red"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "ma-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-h4 font-weight-bold mb-2 py-2" },
                      [_vm._v("Historical data")]
                    ),
                    _c(
                      "v-card",
                      [
                        _c("v-card-text", [
                          _c(
                            "div",
                            { staticStyle: { height: "320px" } },
                            [
                              !_vm.atmoDataSetsLoading.includes(device.id)
                                ? _c("atmo-chart", {
                                    staticStyle: { height: "320px" },
                                    attrs: { data: _vm.atmoDataSets[device.id] }
                                  })
                                : _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: { indeterminate: "" }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "ma-2 py-2" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "align-items-center" },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _c(
                            "div",
                            { staticClass: "text-h4 font-weight-bold" },
                            [_vm._v("Live data")]
                          )
                        ]),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "justify-end text-right align-content-end",
                            attrs: { cols: "6" }
                          },
                          [
                            _vm._v(
                              " Latest measurement: " +
                                _vm._s(
                                  new Date(
                                    device.latestMeasurement.dtm
                                  ).toLocaleTimeString("en-US")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._l(_vm.measurementsList, function(measurement) {
                return _c(
                  "v-col",
                  {
                    key: measurement.id,
                    attrs: {
                      cols: "12",
                      xs: "12",
                      sm: "12",
                      md: "6",
                      lg: "4",
                      xl: "3",
                      xxl: "3"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ma-2" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "mx-auto pt-2 px-2 pb-0",
                            staticStyle: { "min-height": "170px" }
                          },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "10" } }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h5 grey--text font-weight-bold",
                                          staticStyle: {
                                            "letter-spacing":
                                              "-0.5px !important"
                                          }
                                        },
                                        [_vm._v(_vm._s(measurement.title))]
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "d-flex justify-end align-content-end",
                                        attrs: { cols: "2" }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.calcScore(
                                                measurement,
                                                device.latestMeasurement[
                                                  measurement.id
                                                ]
                                              )
                                            ) + " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "8" } }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h3 text--primary font-weight-bold",
                                          staticStyle: {
                                            "letter-spacing": "-1px !important"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                device.latestMeasurement[
                                                  measurement.id
                                                ]
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("br"),
                                      _c("span", { staticClass: "text-h5" }, [
                                        _vm._v(
                                          " " + _vm._s(measurement.suffix) + " "
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "4" }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-2",
                                            attrs: { size: "42" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(measurement.icon) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }