var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "p",
              { staticClass: "text-h3 font-weight-bold d-inline-block mb-0" },
              [_vm._v("PM10")]
            )
          ]),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c("div", { staticClass: "align-end text-right" }, [
              _c(
                "p",
                { staticClass: "text-h5 grey--text font-weight-bold mb-0" },
                [_vm._v("μg/m3")]
              )
            ])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-8 mb-8" }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-justify" }, [
      _c("p", [
        _vm._v(
          " Fine particles are one of the primary forms of air pollution. They result from the combustion of fossil fuels, industrial processes, as well as everyday activities. These particles can be classified into different categories: "
        )
      ]),
      _c("p", [_vm._v("• PM10")]),
      _c("p", [_vm._v("• PM2.5")]),
      _c("p", [_vm._v("• PM1")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }